var OK = window.OK || (window.OK = {});

/**
 * Используется при запросах так:
 * var request = $.ajax({
 *       type: 'POST',
 *       url: [url], // insert here correct url
 *       data: {"gwt.requested": window.pageCtx.gwtHash},
 *       headers: {"TKN": OK.tkn.get()}
 * });
 */
OK.tkn = (function () {
    var token = undefined;

    return {
        set(newToken) {
            token = newToken;
        },
        get() {
            return token;
        }
    };
}());
