var OK = window.OK || (window.OK = {});

OK.loader = (function () {
    var COMMA = ',';
    var READY_STATUS_COMPLETE = 'complete';
    var READY_STATUS_LOADED = 'loaded';

    var okConstants = null;
    var staticUrl = null;
    var pageContext = null;
    var globalScriptsArray = {};
    var head = null;
    var isVariablesInited = false;
    var callbackQueue = {};

    function buildScriptUrl(scriptName) {
        return {
            src: staticUrl + getPageContextValue(scriptName)
        };
    }

    function initVariables() {
        if (isVariablesInited) {
            return;
        }
        isVariablesInited = true;

        okConstants = OK.cnst;
        staticUrl = okConstants.staticUrl;
        pageContext = okConstants.pageCtx;
        head = document.getElementsByTagName('head')[0];

        OK.util.extend(globalScriptsArray, {
            // global script definition list
            abstractHooks: buildScriptUrl('abstractHooksSrc'),
            OKCustomJs: buildScriptUrl('bottomJsSrc'),
            OKTextarea: buildScriptUrl('textareaJsSrc'),
            OKPhotoUploader: buildScriptUrl('photoUploaderJsSrc'),
            OKRegJs: buildScriptUrl('regJsSrc'),
            cdnNode: buildScriptUrl('cdnNodeSrc'),
            OKPromo: buildScriptUrl('promoAppJsSrc'),
            OKGifts: buildScriptUrl('giftsJsSrc'),
            OKAppEdit: buildScriptUrl('appEditJsSrc')
        });
    }

    function getPageContextValue(key) {
        if (pageContext != null && key in pageContext) {
            return pageContext[key];
        }

        return '';
    }

    function insertScript(scriptObj, callback) {
        var script = document.createElement('script');
        scriptObj.waiting = true;
        script.onload = script.onreadystatechange = function () {
            var readyState = script.readyState;

            if (readyState && readyState !== READY_STATUS_COMPLETE && readyState !== READY_STATUS_LOADED) {
                return;
            }
            script.onload = script.onreadystatechange = null;
            scriptObj.ok = true;
            try {
                callback.call(this);
            } catch (e) {
                OK.logging.alert(`Loader callback error after script loaded: ${e.message}`);
            }
        };
        script.async = 1;
        script.src = scriptObj.src;
        head.appendChild(script);
    }

    function fetchScript(globalScriptObj) {
        // fetch script only if it is not ready and not in progress
        if (!globalScriptObj.ok && !globalScriptObj.waiting) {
            insertScript(
                globalScriptObj,
                // callback functions after script is loaded
                function () {
                    // walk thru a loop of stored callbacks
                    // top loop is loop thru callback kies (they are requested scripts as concatenated string, e.g. "jQuery, scriptBottom, swfobject" )
                    for (var key in callbackQueue) {
                        // middle loop is thru different calls with the same key
                        for (var i = 0; i < callbackQueue[key].length; i++) {
                            var currentCallback = callbackQueue[key][i];
                            // check if current callback is not removed from queue
                            if (currentCallback == null) {
                                continue;
                            }
                            var scriptsToLoadArray = currentCallback.srcArr;
                            var allScriptsForCurrentCallbackAreLoaded = 1;
                            // bottom loop is thwu array of requested scripts to check if all scripts for current callback are loaded
                            for (var j = 0; j < scriptsToLoadArray.length; j++) {
                                // if current requested script is not loaded - mark it
                                if (!globalScriptsArray[scriptsToLoadArray[j]].ok) {
                                    allScriptsForCurrentCallbackAreLoaded = 0;
                                }
                            }
                            // check if all scripts for current callback are loaded
                            if (allScriptsForCurrentCallbackAreLoaded) {
                                // execute callback
                                try {
                                    callbackQueue[key][i].clbk.call();
                                } catch (e) {
                                    OK.logging.alert(`Loader queue callback error after all script loaded [${scriptsToLoadArray.join()}]: ${e.message}\n${e.stack}`);
                                }
                                //remove it from queue
                                callbackQueue[key][i] = null;
                            }
                        }

                    }
                })
        }
    }

    function execute(scriptsToLoadArray, callback) {
        initVariables();

        var passedScriptsAreValid = 1;
        var allScriptAreLoaded = 1;
        var globalScriptObj = null;

        if (typeof scriptsToLoadArray === 'string') {
            scriptsToLoadArray = scriptsToLoadArray.split(COMMA);
        }
        // Load jQuery with requirejs
        var jqIndex = Array.prototype.indexOf.call(scriptsToLoadArray, 'jQuery'); // IE8 умеет только так
        var deps = [];
        if (jqIndex >= 0) {
            scriptsToLoadArray.splice(jqIndex, 1);
            deps.push('jquery');
        }
        var requireCallback = function () {
            // here we check if all requested scripts are defined in _globalScriptsArray_
            for (var i = 0; i < scriptsToLoadArray.length; i++) {
                var scriptKey = scriptsToLoadArray[i];
                globalScriptObj = globalScriptsArray[scriptKey];
                if (!globalScriptObj) {
                    // script is not defined - exit
                    passedScriptsAreValid = 0;
                    allScriptAreLoaded = 0;
                    break;
                }
                if (!globalScriptObj.ok) {
                    // any valid script is not loaded yet
                    allScriptAreLoaded = 0;
                }
            }

            if (!passedScriptsAreValid) {
                // some script is not defined - exit
                return;
            }

            if (allScriptAreLoaded) {
                //all scripts are loaded already - simply execute callback
                try {
                    callback.apply();
                } catch (e) {
                    OK.logging.alert("Loader callback error after immediate execute " + "[" + scriptsToLoadArray.join() + "]: " + e.message + "\n" + e.stack);
                }
                return;
            }
            // use concatenated scripts as key to store callback for future execution (when all requested scripts are loaded)
            var callbackKey = scriptsToLoadArray.join(COMMA);
            if (!callbackQueue[callbackKey]) {
                // create new callback storage if it doesn't exist
                callbackQueue[callbackKey] = [];
            }
            // store callback for future execution
            callbackQueue[callbackKey].push({
                // scriptsToLoadArray
                srcArr: scriptsToLoadArray,
                // function to call
                clbk: callback
            });
            // fetch all requested scripts in a loop
            for (i = 0; i < scriptsToLoadArray.length; i++) {
                // get script object from global definition list
                globalScriptObj = globalScriptsArray[scriptsToLoadArray[i]];
                // try to fetch script
                fetchScript(globalScriptObj);
            }
        };

        if (deps.length === 0) {
            requireCallback();
        } else if (deps.length === 1 && require.defined(deps[0])) {
            requireCallback(require(deps[0]));
        } else {
            require(deps, requireCallback);
        }
    }

    function executeRequire(/* string */ moduleName, /* function(moduleName) */ callback, /* function */ onError) {
        if (require.defined(moduleName)) {
            var module = require(moduleName);
            callback(module.default || module); // synchronously execute
        } else {
            require([moduleName], function (module) {
                callback(module.default || module);
            }, onError);
        }
    }

    function register(scripts) {
        OK.util.extend(globalScriptsArray, scripts);
    }

    return {
        /**
         * Asynchronously loads all required scripts and then executes callback
         * @param scripts Array or String of all required scripts. String must be joined with comma (e.g. "jQuery,swfobject"). Array as usual: ["jQuery","swfobject"]
         * @param callback callback function to be executed when all required scripts are loaded
         */
        use: execute,
        /**
         * Synchronously execute callback if module just loaded, else asynchronously load module and execute callback
         * @param require requireJs module name
         * @param callback callback function to be executed. pass module to parameter
         * @param onError onError function to be executed if was error on load
         */
        execRequire: executeRequire,
        register: register
    };
})();
