var OK = window.OK || (window.OK = {});

OK.configurations = (function () {
    var configStore = {};
    return {
        get: function (alias) {
            return new Promise(function (resolve) {
                var config = configStore[alias];
                if (config) {
                    resolve(config)
                } else {
                    require(['OK/utils/vanilla'], function (vanilla) {
                        vanilla.ajax({
                            type: 'GET',
                            url: '/web-api/pms?name=' + encodeURIComponent(alias),
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            dataType: 'json'
                        }).then(function (result) {
                            config = JSON.parse(result.response.data);
                            configStore[alias] = config;
                            resolve(config);
                        }, function () {
                            require(['OK/logger'], function (logger) {
                                logger.error('configurations.loading.error', alias);
                            });
                        })
                    });
                }
            });
        }
    };
})();
