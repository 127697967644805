var OK = window.OK || (window.OK = {});

/**
 * @namespace Holds device-related helpers.
 */
OK.device = (function () {
    return {
        /**
         * @public
         * @type Boolean
         */
        isTouch: ("ontouchend" in window)
    };
}());
