var OK = window.OK || (window.OK = {});

OK.topPanelHeight = function () {
    var cl = document.documentElement.classList;

    if (cl.contains("small-toolbar") || cl.contains("smooth-hiding")) {
        return 48;
    }

    if (cl.contains("new-external-mrg-toolbar")) {
        return 85;
    }

    return 76;
};
