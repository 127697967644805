var OK = window.OK || (window.OK = {});

OK.form = (function () {
    var
        formTagName = 'form',
        submit = function (element) {
            var parent = element.parentNode;
            while (parent && parent.tagName && parent.tagName.toLowerCase() !== formTagName) {
                parent = parent.parentNode;
            }
            if (parent && parent.submit) {
                parent.submit();
            }
            return false;
        },
        selectCheckboxs = function (mainbox, boxname) {
            var elems = mainbox.form.elements;
            var elemnum = elems.length;
            for (i = 0; i < elemnum; i++)
                if (elems[i].name.substr(0, boxname.length) === boxname) elems[i].checked = mainbox.checked;
        },
        inputActiveClass = "input__active";

    return {
        submit: function (element) {
            return submit(element);
        },
        selectCBxs: function (mainbox, boxname) {
            selectCheckboxs(mainbox, boxname);
        },
        placeholder: function (el, placeholder) {
            require(['jquery'], function () {
                var wrapper = placeholder.parent();
                var job = function () {
                    var value = el.attr('contentEditable') === 'true' ? el.html() : el.val();
                    var oldValue = el.data('val') || "";
                    if (value !== oldValue) {
                        if (value !== "") {
                            wrapper.addClass(inputActiveClass)
                        } else {
                            wrapper.removeClass(inputActiveClass);
                        }

                        el.data('val', value);
                        clearInterval(interval)
                    }
                };
                var interval = setInterval(job, 10);
                el.bind('blur', function () {
                    clearInterval(interval);
                });
            });
        }
    };
}());
