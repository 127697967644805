var OK = window.OK || (window.OK = {});

OK.fn = {
    /**
     * Пустая функция
     */
    empty(): void {},
    /**
     * Включен ли дебаг. Этот элемент выставлен на дэве и тесте в настройке odnoklassniki-web / app.layout.html
     */
    isDebug(): boolean {
        return document.getElementById('__gwtd__m') != null;
    },
    /**
     * Надо ли отображать алерты (через window.alert()). Этот элемент выставлен на дэве и тесте в настройке odnoklassniki-web / app.layout.html
     */
    isAlertsEnabled(): boolean {
        return document.getElementById('__alerts-enabled') != null;
    },
    /**
     * Возвращает функцию у которой первые params.length параметров зафиксированы в значения равные params.
     * @param targetFn целевая функция
     * @param params зафиксированные параметры
     * @param context лочим контекст для делегата (нужно для правильного делегирования в gwt)
     */
    delegate(targetFn: Function, params?: any, context?: any): Function {
        return function (this: any): any {
            const args = Array.prototype.slice.call(arguments);  // копируем текущие аргументы в массив
            const targetParams = (params || []).concat(args); // объединяем закаренные и текущие аргументы
            return targetFn.apply(context || this, targetParams); // дергаем целевую функцию
        };
    },

    deferred(context: Record<string, Function>, fnName: string, args: any[]): Function {
        args = Array.prototype.slice.call(args);  // копируем текущие аргументы в массив
        return function (): any {
            const targetFn = context[fnName];
            return targetFn.apply(context, args); // дергаем целевую функцию
        };
    },
    /**
     * Обертка, логирующая все ошибки при вызове targetFn. В случае ошибки вызывает p_loggerFn || OK.logging.currentLoggerFn
     * с единственным параметром native js exception.
     *
     *
     * @param targetFn целевая функция
     * @param loggerFn (optional) функция, которой делегируется логгирование ошибки. Если не указано, то текущий логгер из OK.logging
     *
     */
    logging(targetFn: Function, loggerFn: Function = OK.logging.currentLoggerFn || OK.fn.empty): Function {
        return function (this: any): any {
            try {
                return targetFn.apply(this, arguments);   // дергаем целевую функцию
            } catch (ex) {
                OK.Tracer.error(ex);
                loggerFn.call(window, ex);
            }
        }
    }
}
