var OK = window.OK || (window.OK = {});

OK.css = (function () {
    var setDisplay = function (idOrElement, isShow) {
        var element = (typeof idOrElement == "string") ? document.getElementById(idOrElement) : idOrElement;
        if (!element) {
            return;
        }
        element.style.display = isShow ? "block" : "none";
    };

    return {
        display: function (id, isShow) {
            setDisplay(id, isShow);
        },
        /**
         *
         * @param {string} el
         * @param {string} className
         */
        addClass: function (el, className) {
            var element = document.getElementById(el);
            if (!element) {
                return;
            }
            element.classList.add(className);
        },
        /**
         *
         * @param {string} el
         * @param {string} className
         */
        removeClass: function (el, className) {
            var element = document.getElementById(el);
            if (!element) {
                return;
            }
            element.classList.remove(className);
        }
    };
}());
