var OK = window.OK || (window.OK = {});

/**
 * Открывает доступ из js к UserClientCache.
 * Реализация будет подставлена после активации хука.
 * Если gwt нет (анонимка), то будет использоваться то, что есть...
 *
 * @see UserClientCacheHook.
 */
OK.userCache = { // it will be replaced by GWT.
    _users: [],
    getFromUserCache: function (userId) {
        return this._users[userId];
    },
    updateUserCache: function (usersMap) {
        for (var userId in usersMap) {
            this._users[userId] = usersMap[userId];
        }
    },
    getLinkOnUserAvatar: function (user) {
        return '';
    }
};
