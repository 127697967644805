var OK = window.OK || (window.OK = {});

// Заглушка в отсутствие ГВТ
OK.isStateRedesign = OK.isStateRedesign || function () {
    return false;
};

OK.getRedesignVersion = OK.getRedesignVersion || function () {
    return null;
};

// Внимание! Синхронизировать с one.app.community.dk.gwt.desktop.client.ClientFlag
OK.ClientFlag = {
    MONEY_SAVE_V2: 'ms',
    DEFERRED_CSS: 'dcss',
    MOVIE_POSTING_V2: 'mpv2',
};

window.parseFlags = function (flags) {
    var result = {};
    (flags || '').split(/;/g).forEach(function (flag) {
        var parts = flag.split(':');
        if (parts.length === 2) {
            result[parts[0]] = parts[1];
        }
    });
    return result;
}

OK.isClientFlagEnabled = OK.isClientFlagEnabled || function (flag) {
    return parseFlags(OK.getClientFlags())[flag] === '1';
};

OK.getClientFlags = OK.getClientFlags || function () {
    return document.documentElement.getAttribute('data-client-state');
};

OK.setClientFlags = OK.setClientFlags || function (flags) {
    document.documentElement.setAttribute('data-client-state', flags);
};

OK.setClientFlag = OK.setClientFlag || function (flag, enabled) {
    var flags = parseFlags(OK.getClientFlags());
    flags[flag] = enabled ? '1' : '0';
    var result = Object.getOwnPropertyNames(flags).map(function (flag) {
        return flag + ':' + flags[flag];
    }).join(';');
    OK.setClientFlags(result);
};

OK.CLIENT_FLAGS_HEADER = 'X-Client-Flags';

OK.STAT_ID_HEADER = OK.STAT_ID_HEADER || 'X-Stat-Id';

OK.STAT_ID_PARAMETER = OK.STAT_ID_PARAMETER || 'statId';

OK.getStatId = OK.getStatId || function () {
    return '';
};
