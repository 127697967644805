var OK = window.OK || (window.OK = {});

OK.historyManager = class HistoryManager {
    /** Id продюсера на который будем подписывать обработчики в OK.il */
    private static readonly PRODUCER_EVENT_ID: string = 'histmngr';
    private static currentState: string = '/';
    /** Кэш для сохранения query параметров для разных ссылок */
    private static shortLinkCache: Record<string, string> = {};

    /**
     * Поддерживается ли historyApi в текущем браузере пользователя.
     * По данным caniuse должно поддерживаться у всех пользователей.
     */
    public static isHistorySupported(): boolean {
        return true;
    }

    /**
     * Функция инициализации обработчика на смену url в адресной строке.
     * Обработчик реагирует на изменения url и вызывает всех подписанных обработчиков.
     */
    public static init(): void {
        const state = HistoryManager.getState();
        // при первой инициализации сохраняем hash в History
        const stateWithHash = state + location.hash;
        HistoryManager.replaceState(stateWithHash);

        window.onpopstate = HistoryManager.onPopState;
    }

    /**
     * Получить текущее состояние historyManager. То что находится в адресной строке
     */
    public static getState(): string {
        return location.pathname + location.search;
    }

    /**
     * Замена текущего состояния (ссылки) в адресной строке. По back к нему уже не вернутся.
     */
    public static replaceState(state: string): void {
        const token = HistoryManager.normalizeToken(state);

        HistoryManager.currentState = token;
        window.history.replaceState(token, document.title, token);
    }

    /**
     * Добавление ссылки в адресную строку. Клавиша back вернёт на текущее состояние.
     * @param state - url для адресной строки
     * @param query - доп параметры, которые уйдут вместе с урл, если пользователь нажмёт back.
     */
    public static pushState(state: string, query: string): void {
        const token = HistoryManager.normalizeToken(state);

        if (query) {
            HistoryManager.putItemToCache(token, query);
        }

        // Пока мы не умеем работать с хешами, но При первом открытии,
        // если в ссылке есть хеш, его надо сохранить,
        // поскольку миниапы используют хеши в качестве query.
        // Для внутренних ссылок вместо хеша мы используем параметр st.hash
        if (HistoryManager.isSameStateButWithNewHash(token)) {
            HistoryManager.replaceState(token);
        } else if (HistoryManager.isANewState(token)) {
            HistoryManager.currentState = token;
            window.history.pushState(token, document.title, token);
        }
    }

    public static putItemToCache(url: string, query: string): void {
        HistoryManager.shortLinkCache[HistoryManager.getBasePath(url)] = query;
    }

    public static getItemFromCache(base: string): string {
        return HistoryManager.shortLinkCache[base];
    }

    public static back(): void {
        window.history.back();
    }

    public static subscribeOnHistoryChange(handlerId: string, handle: (token: string) => void): void {
        OK.il.ah({
            id: HistoryManager.PRODUCER_EVENT_ID,
            handle,
            handlerId
        });
    }

    public static unsubscribeOnHistoryChange(handlerId: string): void {
        OK.il.rh({
            id: HistoryManager.PRODUCER_EVENT_ID,
            handlerId
        });
    }

    private static fireAllSubscribers(state: string): void {
        OK.il.f(state, HistoryManager.PRODUCER_EVENT_ID, null);
    }

    private static onPopState(event: PopStateEvent): void {
        try {
            const localToken = event.state;

            if (HistoryManager.isANewState(localToken)) {
                HistoryManager.currentState = localToken;
                // вызываем все подписанные обработчики с новым url, при этом убираем из него query и hash, чтобы не ломать навигацию
                HistoryManager.fireAllSubscribers(HistoryManager.getBasePath(localToken));
            }
        } catch (error) {
            OK.logger.error('historyManager', `method: init, messge: ${(error as Error).message}`);
        }
    }

    private static isANewState(token: string): boolean {
        return !!token && HistoryManager.getBasePath(HistoryManager.currentState) !== HistoryManager.getBasePath(token);
    }

    private static isSameStateButWithNewHash(token: string): boolean {
        const { pathname: currentPath, hash: currentHash } = HistoryManager.buildUrl(HistoryManager.currentState);
        const { pathname: nextPath, hash: nextHash } = HistoryManager.buildUrl(token);

        // нас интересует только случай, когда хэш добавился, либо его значение поменялось: /feed => /feed#hash и /feed#old => /feed#new
        // если действительно необходимо удалить хеш из url, надо вызвать OK.historyManager.replaceState
        return nextPath === currentPath && !!nextHash && nextHash !== currentHash;
    }

    private static normalizeToken(token: string): string {
        if (!token) {
            return '/';
        }

        if (!token.startsWith('/')) {
            return '/dk?' + token;
        }

        if (token.includes('/dk?')) {
            return token;
        }

        const url = HistoryManager.buildUrl(token);
        url.search = '';

        return url.toString();
    }

    private static buildUrl(url: string): URL {
        return new URL(url, document.baseURI);
    }

    private static getBasePath(url: string): string {
        return HistoryManager.buildUrl(url).pathname;
    }
}

OK.historyManager.init();
