var OK = window.OK || (window.OK = {});

/**
 * Обработчик не обработанных кликов по содержимому контейнера.
 * см. DivAction.html (elem != null)
 *
 * Обработчик клика по ссылке открытия леера топиков.
 * см. LinkAction.html (elem == null)
 */
OK.showTopicInLayerOnClick = function (elem, event, topicId, ownerType, activityId, closeTopicId, closeOwnerType, toBlockIndex) {
    if (elem) {
        for (var e = OK.eventTarget(event); e && e != elem; e = e.parentNode) {
            if (e.tagName === 'A') {
                return; // Игнорируем клики по ссылкам внутри дива. Ссылки сами разберутся что делать.
            }
        }
    }
    var layer = document.getElementById('mtLayer');
    if (layer) {
        require(['OK/MediaLayer'], function (mtLayer) {
            mtLayer.showTopicInLayer(topicId, ownerType, activityId,
                false,
                '', null, null,
                false,
                closeTopicId, closeOwnerType, false, toBlockIndex);
        });
        return OK.stop(event);
    }
};
