(function (window) {
    var STATE_ID_HEADER = 'X-StateId';
    var currentStateId = document.documentElement.getAttribute('data-initial-state-id');

    function setCurrentStateId(stateId) {
        currentStateId = stateId;
    }

    function processXhrResponse(xhr) {
        var stateId = xhr.getResponseHeader(STATE_ID_HEADER);
        if (stateId) {
            setCurrentStateId(stateId);
        }
    }

    function getCurrentStateId() {
        return currentStateId;
    }

    window.OK = window.OK || {};
    window.OK.state = {
        setCurrentStateId: setCurrentStateId,
        processXhrResponse: processXhrResponse,
        getCurrentStateId: getCurrentStateId
    };

    if (!window.OK.getCurrentDesktopModelId) {
        window.OK.getCurrentDesktopModelId = getCurrentStateId;
    }
})(window);
