var OK = window.OK || (window.OK = {});

OK.util = (function (w, d) {

    var htmlClassList = d.documentElement.classList;

    return {
        isGecko: function () {
            return htmlClassList.contains('gecko');
        },
        isWebkit: function () {
            return htmlClassList.contains('webkit');
        },
        isSafari: function () {
            return htmlClassList.contains('mac-saf');
        },
        isIEMetro: function () {
            var result = false;
            if (w.navigator.msPointerEnabled) {
                try {
                    // IE Metro does not support ActiveX
                    new ActiveXObject("htmlfile");
                } catch (e) {
                    // http://stackoverflow.com/a/24622641
                    result = (w.screenY === 0 && (w.innerHeight + 1) !== w.outerHeight);
                }
            }
            return result;
        },
        counterId: 0,

        extend: function (to, from) {
            to = to || {};
            for (var i in from) {
                if (from.hasOwnProperty(i)) {
                    to[i] = from[i];
                }
            }
            return to;
        },

        clean: function (obj) {
            for (var i in obj) {
                if (obj.hasOwnProperty(i)) {
                    obj[i] = null;
                }
            }
        },

        keys: function (hash) {
            var keys = [];
            for (var i in hash) {
                if (hash.hasOwnProperty(i)) {
                    keys.push(i);
                }
            }
            return keys;
        },

        isHighDensity: function () {
            return (
                (
                    w.matchMedia && (
                        w.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches
                        || w.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches
                    )
                )
                ||
                (
                    w.devicePixelRatio && w.devicePixelRatio > 1.3
                ));
        },

        setHighDensityCookie: function (cookieName) {
            var cookie = cookieName + "=";
            if (OK.util.isHighDensity()) {
                cookie += "h;max-age=31536000;path=/";
            } else {
                cookie += ";expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
            }
            d.cookie = cookie;
        },


        plurals: function (n) {
            n = Math.abs(n) % 100;
            if (n > 10 && n < 20) {
                return 5;
            }
            var lastDigit = n % 10;
            if (lastDigit > 1 && lastDigit < 5) {
                return 2;
            }
            if (lastDigit == 1) {
                return 1;
            }
            return 5;
        },

        /**
         * Parse OK-style JSON - remove html comments marks, e.t.c.
         *
         * @deprecated Скорее всего вам нужен метод parseJsonCorrected()
         * @param {String} json JSON String
         * @return {*} parsed json
         */
        parseJSON: function (json) {

            var COMMENT_START = '<!--',
                COMMENT_START_LEN = COMMENT_START.length,
                COMMENT_END_LEN = '-->'.length;

            if (json) {
                if (json.indexOf(COMMENT_START) === 0) {
                    json = json.substring(COMMENT_START_LEN, json.length - COMMENT_END_LEN);
                }

                return JSON.parse(json);
            }
        },

        /**
         * Парсит содержимое елемента, которое было построено через вызовы:
         * one.app.community.dk.gwt.hook.server.HookServerAdapter#createSerializableData()
         * one.app.community.dk.gwt.hook.server.HookServerAdapter#createSerializableDataText()
         * @param {string} json
         * @return {{}|null}
         * @throws SyntaxError
         */
        parseJsonCorrected: function (json) {
            var COMMENT_START = '<!--',
                COMMENT_END = '-->',
                COMMENT_START_LEN = COMMENT_START.length,
                COMMENT_END_LEN = COMMENT_END.length;

            if (!json) {
                return null;
            }

            if (json.indexOf(COMMENT_START) === 0) {
                json = json.substring(COMMENT_START_LEN, json.length - COMMENT_END_LEN).replace(/\\u0026#45;\\u0026#45;/g, '--');

                if (!json.length) {
                    return null;
                }
            }

            return JSON.parse(json);
        },

        nextId: function () {
            return this.counterId++;
        },

        /**
         * Calculates element offset relative to document.
         * @param {Element} element
         * @return {{left: number, top: number}}
         */
        getOffset: function (element) {

            var offset = {left: 0, top: 0};

            do {
                offset.left += element.offsetLeft;
                offset.top += element.offsetTop;
            } while (element = element.offsetParent);

            return offset;
        },

        is4ColumnActive: function () {
            return window.matchMedia("screen and (min-width: 1274px)").matches;
        },

        /**
         * @param {XMLHttpRequest} xhr
         */
        processCssHeaders: function (xhr) {
            return new Promise(function (resolve) {
                var cssToLoad = xhr.getResponseHeader('required-css');

                if (cssToLoad) {

                    var cssRequire = [];

                    cssToLoad.split(',').forEach(function (css) {
                        if (css) {
                            cssRequire.push('OK/css-loader!' + css);
                        }
                    });

                    if (cssRequire.length) {
                        require(cssRequire, resolve, resolve);
                        return;
                    }
                }

                resolve();
            });
        }
    }
}(window, document));
