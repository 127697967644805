var OK = window.OK || (window.OK = {});

OK.hookModel = OK.hookModel || {
    getHookById: OK.fn.empty,
    removeHookById: OK.fn.empty,
    getNearestBlockHookId: OK.fn.empty,
    /**
     * При инициализации gwt этот метод будет переопрелен для работы с HookModel.
     * @param {String} blockId
     * @param {String} content
     */
    setHookContent: function (blockId, content, _) {
        if (blockId === 'HeadMeta') {
            processNewMetaTags(content)
        } else {
            var el = document.getElementById("hook_Block_" + blockId);
            if (el) {
                if (require.defined('OK/capture')) {
                    require('OK/capture').deactivate(el);
                }
                el.innerHTML = content;
                require(['OK/capture'], function (capture) {
                    capture.activate(el);
                });
            }
        }

        function processNewMetaTags(content) {
            var head = document.getElementsByTagName('head')[0];
            var headElements = head.childNodes;
            var deletionMode = false;
            for (var i = 0; i < headElements.length;) {
                el = headElements[i];
                if (el.nodeType === 8 && el.textContent.indexOf("META START") > -1) {
                    deletionMode = true;
                }
                if (deletionMode) {
                    head.removeChild(el);
                } else {
                    i++;
                }
                if (el.nodeType === 8 && el.textContent.indexOf("META END") > -1) {
                    break;
                }
            }
            if (deletionMode) { // старые мета-тэги нашли и удалили, теперь вставим новые
                head.insertAdjacentHTML('afterbegin', content);
            }
        }
    },

    captureBlockHook: OK.fn.empty,

    isHookEmpty: function (hookId) {
        var hook = this.getHookById(hookId);
        return !hook || !hook.element || !hook.element.childNodes || !hook.element.childNodes.length;
    }
};
