var OK = window.OK || (window.OK = {});

OK.favicon = (function () {
    return {
        change: function (url) {
            var iconOriginal = document.getElementById("favicon");
            if (!iconOriginal) return;
            var favicon = iconOriginal.cloneNode(true);
            favicon.setAttribute("href", url);
            iconOriginal.parentNode.replaceChild(favicon, iconOriginal);
        }
    };
}());
