var OK = window.OK || (window.OK = {});

/** namespace для всех лееров */
(function () {

    var MODAL_OVERLAY_CLASSES = '.modal_overlay, .modal-new_close_ovr, .layer_ovr, .ic_close, #hook_Block_PromoMainLayer .layerPanelClose, .js-close-layer';

    // stack array
    var stack = [],
        isEscDown; // becomes "true" when "keydown" event fires

    var layersStack = [];
    var layersMaxSize = 1;

    var listeners = {};

    var globalListeners = new Set();

    var subscribe = function (layerId, callback) {
        if (!listeners.hasOwnProperty(layerId)) {
            listeners[layerId] = [callback];
        } else {
            listeners[layerId].push(callback);
        }
    };

    var unsubscribe = function (layerId, callback) {
        if (!callback) {
            listeners[layerId] = [];
        } else {
            if (!listeners[layerId]) {
                return;
            }
            var index = listeners[layerId].indexOf(callback);
            if (index > -1) {
                listeners[layerId].splice(index, 1);
            }
        }
    };

    var subscribeGlobal = function (callback) {
        globalListeners.add(callback);
    };

    var unsubscribeGlobal = function (callback) {
        globalListeners.delete(callback);
    };

    var _call = function (layerId, status, result) {
        var topLayerId = getTopLayerId();
        if (topLayerId) {
            OK.Tracer.setErrorKey('layerId', topLayerId);
        } else {
            OK.Tracer.errorModule?.removeErrorKey('layerId');
        }
        if (OK.fn.isDebug()) {
            console.log("layer._call('" + layerId + "', '" + JSON.stringify(status) + "', '" + JSON.stringify(result) + "') topLayer = " + JSON.stringify(topLayerId));
        }


        if (listeners.hasOwnProperty(layerId)) {
            //  Подпичики частенько отписываются на лету, что ломает порядок колбеков в listeners.
            //  Перед проходом надо создать копию, которая не сломается.
            var listenersCopy = [];
            var layerListeners = listeners[layerId];
            for (i = 0; i < layerListeners.length; i++) {
                listenersCopy[i] = layerListeners[i];
            }

            for (var i = 0, l = listenersCopy.length; i < l; i++) {
                try {
                    listenersCopy[i].call(null, status, result, layerId);
                } catch (e) {
                    // Do nothing
                }
            }
        }
        //  Подпичики частенько отписываются на лету, что ломает порядок колбеков в listeners.
        //  Перед проходом надо создать копию, которая не сломается.
        Array.from(globalListeners).forEach(function (cb) {
            cb.call(null, layerId, status, result);
        });
    };

    var clear = function () {
        layersStack = [];
    };

    var setMaxSize = function (count) {
        layersMaxSize = count;
    };

    var prepareUrl = function (url) {
        //Убираем маркер текущего коммента и направление листания
        return url.replace(/st\.(fwd|layer\.direction)\=.*?(&|$)/g, '');
    };

    var push = function (link) {
        if (layersMaxSize <= 1) {
            return;
        }
        link = prepareUrl(link);
        layersStack.push({link: link});
        var dif = layersStack.length - layersMaxSize;
        if (dif > 0) {
            layersStack.splice(0, dif);
        }
    };

    var replace = function (link) {
        if (layersMaxSize <= 1 || layersStack.length === 0) {
            return;
        }

        link = prepareUrl(link);
        layersStack[layersStack.length - 1] = {link: link};
    };

    var changeAttrs = function (marker, isForward) {
        if (layersStack.length === 0) {
            return;
        }
        //Эти параметры рассчитаны на DiscussionBlock, чтобы он открывал нужную страницу комментов
        var attrs = layersStack[layersStack.length - 1].link.indexOf('?') >= 0 ? '&' : '?';
        attrs += 'st.fwd=';
        marker = encodeURIComponent(marker);
        if (isForward) {
            attrs += 'on&st.lastelem=' + marker;
        } else {
            attrs += 'off&st.firstelem=' + marker;
        }
        layersStack[layersStack.length - 1].attrs = attrs;
    };

    var lastUrl = function () {
        if (layersStack.length === 0) {
            return '';
        }

        var layer = layersStack[layersStack.length - 1]
        return layer ? layer.link : '';
    }

    var pop = function () {
        if (layersMaxSize <= 1 || layersStack.length == 0) {
            return null;
        }
        layersStack.splice(layersStack.length - 1, 1);
        if (layersStack.length == 0) {
            return null;
        }
        var data = layersStack[layersStack.length - 1];
        var url = data.link;
        if (data.attrs) {
            url += data.attrs;
        }

        //Дропаем из истории леер, который собираемся открыть. Так как при открытии он снова будет помещен в стэк
        layersStack.splice(layersStack.length - 1, 1);
        if (typeof navigateOnUrlFromJS !== 'undefined') {
            navigateOnUrlFromJS(url);
        } else {
            require(['OK/PopLayerPhoto'], function (handler) {
                handler.handleOnNavigate(url);
            });
        }

        return url;
    };

    var startLoad = function () {
        require(['OK/LayersLoader'], function (LayersLoader) {
            LayersLoader.startLoad();
        });
    }

    var endLoad = function (forceEnd) {
        require(['OK/LayersLoader'], function (LayersLoader) {
            LayersLoader.endLoad(forceEnd);
        });
    }

    /**
     * register to stack. Binded from GWT
     * @param {{
     * layerName?: string,
     * deactivateFn?: Function,
     * hookInstance?: object,
     * preventCloseOnEsc?: boolean,
     * keyDownHandler?: Function,
     * keyUpHandler?: Function,
     * redirectAfterClose?: string,
     * shouldIgnoreA11y?: boolean,
     * // Не разрешаем скрытие лоадера автоматически. Управление состоянием передаём модулю, который прокинул true
     * withStopLayerLoader?: boolean
     * // Кастомный случай, когда нужно передать ивент сторонним элементам в верстке
     * withoutStopPropagation?: boolean
     * }} params
     */
    var open = function (params) {
        if (params.shouldIgnoreA11y) {
            registerWithoutAccessibility(params.layerName, params.deactivateFn, params.hookInstance, params.preventCloseOnEsc, params.keyDownHandler, params.redirectAfterClose, params.keyUpHandler, params.withStopLayerLoader, params.withoutStopPropagation);
        } else {
            register(params.layerName, params.deactivateFn, params.hookInstance, params.preventCloseOnEsc, params.keyDownHandler, params.redirectAfterClose, params.keyUpHandler, params.withStopLayerLoader, params.withoutStopPropagation);
        }
    };

    var registerWithoutAccessibility = function (layerName, deactivateFn, hookInstance, preventCloseOnEsc, keyDownHandler, redirectAfterClose, keyUpHandler, withStopLayerLoader, withoutStopPropagation) {
        OK.loader.execRequire('OK/ToolbarGrowl', function (m) {
            m.hideAll();
        });

        // optional
        hookInstance = hookInstance || null;
        preventCloseOnEsc = preventCloseOnEsc || false;
        keyDownHandler = keyDownHandler || function () {
        };
        keyUpHandler = keyUpHandler || function () {
        };
        redirectAfterClose = redirectAfterClose || null;

        var newEl = {
            id: layerName,
            deactivateFn: deactivateFn,
            hookInstance: hookInstance,
            preventCloseOnEsc: preventCloseOnEsc,
            keyDownHandler: keyDownHandler,
            keyUpHandler: keyUpHandler,
            redirectAfterClose: redirectAfterClose,
            withoutStopPropagation: withoutStopPropagation,
        };

        var reRegister = false;
        // push new layer to stack
        for (var i = 0; i < stack.length; i++) {
            if (stack[i].id === newEl.id) {
                reRegister = true;
                stack.splice(i, 1);
                OK.logging.logger.success("layerManager", "reRegister", newEl.id);
            }
        }
        stack.push(newEl);

        // Start listening to shadow clicks and ESC's if stack isn't empty
        if (stack.length === 1 && !reRegister) {
            bind();
        }

        _call(newEl.id, true);

        toggleGlobalScrolling();
        OK.logging.logger.success("layerManager", "register", newEl.id);

        if (!withStopLayerLoader) {
            endLoad();
        }

        if (document.documentElement.hasAttribute('data-use-hook-activator')) {
            require(['OK/HookViewportActivator'], function (HookViewportActivator) {
                HookViewportActivator.checkAll();
            });
        }
    };

    /**
     * register to stack. Binded from GWT
     * @param {string} layerName
     * @param {Function?} deactivateFn
     * @param {object?} hookInstance
     * @param {boolean?} preventCloseOnEsc
     * @param {Function?} keyDownHandler
     * @param {string?} redirectAfterClose
     * @param {Function?} keyUpHandler
     * @param {boolean?} withStopLayerLoader - Не разрешаем скрытие лоадера автоматически. Управление состоянием передаём модулю, который прокинул true
     * @param {boolean?} withoutStopPropagation
     * @deprecated {@see open}
     */
    var register = function (layerName, deactivateFn, hookInstance, preventCloseOnEsc, keyDownHandler, redirectAfterClose, keyUpHandler, withStopLayerLoader, withoutStopPropagation) {
        registerWithoutAccessibility(layerName, deactivateFn, hookInstance, preventCloseOnEsc, keyDownHandler, redirectAfterClose, keyUpHandler, withStopLayerLoader, withoutStopPropagation);
        require(['OK/AccessibleModal'], function (accessibleModal) {
            accessibleModal.AccessibleModal.registerModal(layerName);
        });
    };

    function unregisterById(id, result) {
        for (var i = 0; i < stack.length; i++) {
            if (stack[i].id === id) {
                var el = stack[i];

                stack.splice(i, 1);

                if (el) {
                    // execute layer's deactivation callback
                    deactivate(el);

                    // when stack becomes empty we need to stop listening for any events
                    if (!stack.length) {
                        unbind();
                    }
                    toggleGlobalScrolling();

                    if (el.redirectAfterClose !== null) {
                        if (OK.navigation.redirect) {
                            OK.navigation.redirect(el.redirectAfterClose);
                        } else {
                            window.location.assign(el.redirectAfterClose);
                        }
                    }

                    _call(el.id, false, result);

                    require(['OK/AccessibleModal'], function (accessibleModal) {
                        accessibleModal.AccessibleModal.removeModal(el.id);
                    });

                    OK.logging.logger.success("layerManager", "unregister", el.id);
                } else {
                    OK.logging.logger.success("layerManager", "popFailed");
                }
            }
        }
    }

    // pop layer from stack
    var unregister = function (eventType, result) {
        endLoad(true);
        // do pop only if stack isn't empty
        if (stack.length) {

            // do pop only for those layers that listen to ESC's and shadow-clicks
            if (!stack[stack.length - 1].preventCloseOnEsc) {

                var el = stack.pop();

                if (el) {
                    // execute layer's deactivation callback
                    deactivate(el);

                    // when stack becomes empty we need to stop listening for any events
                    if (!stack.length) {
                        unbind();
                    }
                    toggleGlobalScrolling();

                    if (el.redirectAfterClose !== null) {
                        if (OK.navigation.redirect) {
                            OK.navigation.redirect(el.redirectAfterClose);
                        } else {
                            window.location.assign(el.redirectAfterClose);
                        }
                    }

                    _call(el.id, false, result);

                    require(['OK/AccessibleModal'], function (accessibleModal) {
                        accessibleModal.AccessibleModal.removeModal(el.id);
                    });

                    OK.logging.logger.success("layerManager", "unregister", el.id);
                    OK.logging.logger.success("layerManager", eventType, el.id); // логируем как закрыт леер: эск или клик в оверлей
                } else {
                    OK.logging.logger.success("layerManager", "popFailed");
                }
            } else {
                OK.logging.logger.success("layerManager", "escDisabledForLayer", stack[stack.length - 1].id);
            }
        } else {
            OK.logging.logger.success("layerManager", "stackEmpty");
        }
    };

    var toggleGlobalScrolling = function () {
        var STACK_IDS = ['posting_form', 'video_player', 'OldFeedPopupLayer'];
        var scrollingOn = !stack.length
            || (stack.length === 1 && (STACK_IDS.indexOf(stack[0].id) !== -1
                || stack[0].id.indexOf('dd-menu') === 0));

        //ХХХ: есть проблема:
        //
        // если какие-то лееры были _скрыты_ при открытии дискуссий,
        // а потом из дискуссий пользователь перешёл по какой-нибудь ссылке на стейт (н-р, профиль друга),
        // то дискуссии закроются, а 'oh' останется и в ленте не будет скролла.
        //
        // Это происходит, т.к. скрытые ЛЕЕРЫ никто специально НЕ УДАЛЯЕТ из layerManager'a (при закрытии дискуссий по doNotRestorePopLayer),
        // а сами они не всегда это делают\могут сделать.
        //
        // В качестве workaround'a добавлена проверка на 'modal_hook' https://jira.odkl.ru/browse/OL-39910.
        var modalHook = (stack.length == 1 && stack[0].id == 'modal_hook');
        if (modalHook) {
            var elem = document.getElementById("hook_Modal_popLayerModal");
            if (elem && elem.classList.contains('layer__disabled')) {
                scrollingOn = true;
            }
        }

        require(['OK/OhManager'], function (ohManager) {
            ohManager.switchScrolling(scrollingOn);
        });
    };

    function notify4246() {
        var fcb = OK.hookModel.getHookById("ForthColumnTopBannerInner");
        if (fcb) {
            fcb.handle();
        }
    }

    // bind nescessary listeners
    var bind = function () {

        OK.loader.use(['jQuery'], function () {
            var body = $("body");

            // bind shadow clicks and ESCs
            body
                .on("click.popLayers", MODAL_OVERLAY_CLASSES, function (e) {
                    unregister("shadowClick");

                    e.stopImmediatePropagation(); //как минимум, при закрытии модального диалога вызывается несколько обработчиков.
                })
                /*
                 OL-40370: Некорректно работает Esc. при закрытии видео в фулскрине
                 События keyup в win-браузерах проваливаются из полноэкранного режима flash в js. Т.е. при нажатии ESC
                 происходит выход из полноэкранного режима и одновременно закрытие леера проигрывателя.
                 При этом с keydown такой проблемы нет. Но событие keydown сгорает множество раз пока зажата клавиша.
                 Поэтому реализован "смешанный" вариант, использующий и keyup и keydown.
                 */
                .on("keydown.popLayers", function (e) {
                    var onTop = stack[stack.length - 1];
                    if (onTop) {
                        onTop.keyDownHandler.call(this, e);
                    }

                    if (e.keyCode === 27 && !isEscDown) {
                        isEscDown = true;
                        unregister("esc");

                        e.preventDefault(); // Opera & IE(?) cancel current xhr request on esc. Не хотим этого!
                        if (!onTop.withoutStopPropagation) {
                            e.stopPropagation();
                        }
                    }
                })
                .on("keyup.popLayers", function (e) {
                    var onTop = stack[stack.length - 1];
                    if (onTop) {
                        onTop.keyUpHandler.call(this, e);
                    }

                    isEscDown = false;

                    if (e.keyCode === 27) {
                        e.preventDefault(); // Opera & IE(?) cancel current xhr request on esc. Не хотим этого!
                    }
                    if (!onTop.withoutStopPropagation) {
                        e.stopPropagation();
                    }
                });
        });

        require(['OK/LayersEventBuses'], function (LayersEventBuses) {
            LayersEventBuses.ANY_OPENED.emit({}, this);
        });
        // скрыть баннер 4246
        notify4246();
    };

    // unbind listeners
    var unbind = function () {

        OK.loader.use(['jQuery'], function () {
            isEscDown = false;

            // unbind clicks and ESCs
            $("body").off(".popLayers");
        });

        require(['OK/ToolbarGrowl'], function (m) {
            m.showAny();
        });

        require(['OK/LayersEventBuses'], function (LayersEventBuses) {
            LayersEventBuses.ANY_CLOSED.emit(null, this);
        });

        // показать баннер 4246
        notify4246();
    };

    // take layer and execute it's deactivation function
    var deactivate = function (layer) {
        layer.deactivateFn.call(layer.hookInstance);
    };

    // remove layer from stack by name. Binded from GWT
    var removeWithoutAccessibility = function (id) {

        var el;

        for (var i = 0; i < stack.length; i++) {
            if (stack[i].id === id) {
                el = stack.splice(i, 1);

                if (el && el[0]) {
                    _call(el[0].id, false);
                }

                OK.logging.logger.success("layerManager", "unregister", id);
            }
        }

        if (!el) {
            OK.logging.logger.success("layerManager", "unregisterLayerNotFound", id);
        }

        // if stack becomes emty then stop listening to events
        if (!stack.length && el) {
            unbind();
        }

        toggleGlobalScrolling();
        endLoad();
    };

    var remove = function (id) {
        removeWithoutAccessibility(id);

        require(['OK/AccessibleModal'], function (accessibleModal) {
            accessibleModal.AccessibleModal.removeModal(id);
        });
    };

    var unregisterAll = function () {
        var stackLength = stack.length;
        for (var i = stackLength; i > 0; i--) {
            unregister("unregisterAll");
        }
    };

    /**
     * Проверяем, открыт ли какой-нибудь леер.
     * @param {boolean} checkDom - нужно ли дополнительно проверить html-страницу на наличие открытого леера
     */
    var isAnyLayerOpened = function (checkDom) {
        if (checkDom) {
            // хак на случай, если скрипт леера ещё не загрузился и не зарегистрировал себя
            if (document.querySelector('.vp-layer,.mlr_cnts,.modal-new_cnt,.vp-layer_stub')) {
                return true;
            }
        }
        return stack.length !== 0;
    };

    var isLayerOpened = function (layerKey) {
        if (stack) {
            for (var i = 0; i < stack.length; i++) {
                var layer = stack[i];
                if (layer.id === layerKey) {
                    return true;
                }
            }
        }
        return false;
    };

    var getTopLayer = function () {
        if (isAnyLayerOpened()) {
            return stack[stack.length - 1];
        }
        return null;
    };

    var getTopLayerId = function () {
        var topLayer = getTopLayer();
        return topLayer ? topLayer.id : null;
    };

    OK.Layers = {
        /**
         * @deprecated {@see open}
         */
        register: register,
        registerWithoutAccessibility: registerWithoutAccessibility,
        open: open,
        remove: remove,
        removeWithoutAccessibility: removeWithoutAccessibility,
        isAnyLayerOpened: isAnyLayerOpened,
        isLayerOpened: isLayerOpened,
        unregisterAll: unregisterAll,
        unregisterLast: unregister,
        unregisterById: unregisterById,
        stack: stack,
        push: push,
        pop: pop,
        lastUrl: lastUrl,
        changeAttrs: changeAttrs,
        clear: clear,
        replace: replace,
        setMaxSize: setMaxSize,
        onLayerShown: OK.fn.empty,
        onLayerHidden: OK.fn.empty,
        subscribe: subscribe,
        unsubscribe: unsubscribe,
        getTopLayer: getTopLayer,
        getTopLayerId: getTopLayerId,
        subscribeGlobal: subscribeGlobal,
        unsubscribeGlobal: unsubscribeGlobal,
        startLoad: startLoad,
        endLoad: endLoad
    };
})();


if (OK.historyManager.isHistorySupported() && !window.onpopstate) {
    window.onpopstate = function (e) {
        /**
         * https://developer.mozilla.org/ru/docs/Web/API/WindowEventHandlers/onpopstate
         * Браузеры по разному обрабатывают событие на странице загрузки. Chrome (prior to v34) и Safari всегда
         * генерируют событие popstate на странице загрузки, тогда как Firefox не делает этого.
         * */
        if (e.state) {
            OK.Layers.unregisterAll();
        }
    }
}
