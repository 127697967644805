var OK = window.OK || (window.OK = {});

/**
 * Namespace для внешних методов, вызываемых из плеера.
 * @type {{}}
 */
OK.VideoPlayer = OK.VideoPlayer || {};

/**
 * Вызывается, когда пользователь нажимает на "Класс" в плеере. Используется в поплеере и в ленте.
 * @param {string} movieId
 */
OK.VideoPlayer.likeVideoFromFlash = function (movieId) {
    if (!window.navigateOnUrlFromJS) {
        // Anonymous page without GWT
        document.location.href = '/';
        return;
    }
    require(['jquery'], function ($) {
        var $oldLikeWidget = $('.controls-list .controls-list_lk[data-l-id=' + movieId + ']:first'),
            $newLikeWidget = $('.widget-list .controls-list_lk[data-id1=' + movieId + ']:first');

        if (!$newLikeWidget.length) {
            // Скрытый виджет в ленте, отображается в новости типа "Добавлено N видео"
            $newLikeWidget = $('.widget .controls-list_lk[data-id1=' + movieId + ']:first');
        }

        $newLikeWidget.length && $newLikeWidget.data('clickFromVideo', true);

        $oldLikeWidget.length && $oldLikeWidget.click();
        $newLikeWidget.length && $newLikeWidget.click();
    });
};

/**
 * Вызывается, когда пользователь нажимает на "Подписаться/отписаться" в плеере. Используется в поплеере.
 * @param {{}} options
 * @param {string} albumId
 * @param {string} videoId
 * @param {string} action (либо "subscribe", либо "unsubscribe")
 * @param {function=} callback
 * @param {string=} activityId
 */
OK.VideoPlayer.toggleSubscriptionFromFlash = function (options, albumId, videoId, action, callback, activityId) {
    require(['OK/utils/utils'], function (utils) {
        var data = {
            'cmd': 'VideoSubscriptionBlock',
            'st.vvs.action': action,
            'st._aid': activityId
        };

        if (options.notifyMovieSubscription) {
            data['st.vvs.vid'] = videoId;
        } else {
            data['st.vvs.aid'] = albumId;
        }

        utils.ajax({
            url: '/dk',
            data: data
        }).done(utils.updateBlockModelCallback).done(callback);
    });
};

/**
 * Вызывается, когда пользователь отвечает на вопрос в аннотации в плеере. Используется в поплеере.
 * @param {number} movieId
 * @param {number} questionId
 * @param {number} answerId
 * @param {function=} callback
 */
OK.VideoPlayer.answerOnPoll = function (movieId, questionId, answerId, callback) {
    require(['OK/utils/utils'], function (utils) {
        utils.ajax({
            type: 'POST',
            url: '/web-api/annotations/poll/answer',
            data: {
                movieId: movieId,
                questionId: questionId,
                answerId: answerId
            }
        }).done(callback);
    });
};

/**
 * Публикация видео ролика в ленте
 * @param {number} movieId
 * @param {function=} callback
 */
OK.VideoPlayer.reshare = function (movieId, callback) {
    require(['OK/utils/utils'], function (utils) {
        utils.ajax({
            type: 'GET',
            url: '/web-api/video/reshare/' + movieId
        }).done(callback);
    });
};

/**
 * Вызывается, когда пользователь нажимает на "Подписаться" в баннере подписки на группу.
 * @param {string} groupId
 * @param {function} callback
 */
OK.VideoPlayer.joinGroup = function (groupId, callback) {
    require(['OK/utils/utils'], function (utils) {
        utils.ajax({
            url: '/dk',
            data: {
                'cmd': 'videoCommand',
                'a': 'joinGroup',
                'gid': groupId
            }
        }).done(callback).fail(callback);
    });
};

/**
 * Вызывается, когда пользователь нажимает на "Подписаться" в аннотации профиля.
 * @param {string} profileId
 * @param {function} callback
 */
OK.VideoPlayer.subscribeProfile = function (profileId, callback) {
    require(['OK/utils/utils'], function (utils) {
        utils.ajax({
            url: '/dk',
            data: {
                'cmd': 'videoCommand',
                'a': 'subscribeProfile',
                'pid': profileId
            }
        }).done(callback).fail(callback);
    });
};

/**
 * Открывает страницу, на которой можно загрузить видео.
 */
OK.VideoPlayer.OKVideoOpenUserUpload = function () {
    window.navigateOnUrlFromJS ?
        window.navigateOnUrlFromJS('/video/myVideo') :
        document.location.href = '/video/myVideo';
};

/**
 * Запоминает видео просмотренным.
 * @param {number} watchedId
 */
OK.VideoPlayer.addWatched = function (watchedId) {
    watchedId = parseInt(watchedId, 10);
    OK.VideoPlayer.storage.addWatched(watchedId);
};


OK.VideoPlayer.openProduct = function (movieId, product) {
    require(['OK/utils/vanilla'], function (vanilla) {
        var requestData;
        if (product.groupId != null) {
            requestData = {
                'cmd': 'PopLayerMediaTopicOpen',
                'st.mt.id': product.id,
                'st.mt.ot': 'GROUP_ADVERT',
                'st.cmd': 'altGroupAdvertsPage',
                'st.groupId': product.groupId
            }
        } else {
            requestData = {
                'cmd': 'PopLayer',
                'st.cmd': 'mall',
                'st.layer.cmd': 'PopLayerMallProduct',
                'st.layer.productId': product.id,
                'st.layer.section': product.section,
                'st.layer.ePT': product.campaignId || 'cn:shoppingtv'
            }
        }
        vanilla.ajax({
            url: '/dk',
            data: requestData
        }).then(function (data) {
            vanilla.updateBlockModelCallback(data);
        });
    });
};

OK.VideoPlayer.openLogin = function () {
    require(['OK/AuthLoginPopup'], function (authLoginPopupModule) {
        authLoginPopupModule.open({
                href: decodeURIComponent(OK.historyManager.getState())
            }
        );
    });
};

/**
 * Хранилище.
 */
OK.VideoPlayer.storage = (function () {
    /**
     * Prefix for LocalStorage keys.
     * @type {string}
     */
    var LS_PREFIX = '_vp_',
        /**
         * Size of watched movies cache
         */
        WATCHED_CACHE_SIZE = 1000,
        /**
         * Size of last playing cache
         */
        LAST_PLAYING_TIME_CACHE_SIZE = 500;

    /**
     * LocalStorage reference.
     * @type {Storage}
     */
    var storage = (function () {
        var id = new Date, st, res;
        try {
            (st = window.localStorage).setItem(id, id);
            res = st.getItem(id) == id;
            st.removeItem(id);
            return res && st;
        } catch (exception) {
        }
    }());

    /**
     * Get item from storage.
     * @param {string} name
     * @return {string|number|[]|boolean}
     * @private
     */
    function _getItem(name) {
        try {
            return storage && JSON.parse(storage.getItem(LS_PREFIX + name));
        } catch (e) {
            return null;
        }
    }

    /**
     * Set item to storage.
     * @param {string} name
     * @param {string|number|{}|[]} value
     * @private
     */
    function _setItem(name, value) {
        try {
            storage && storage.setItem(LS_PREFIX + name, JSON.stringify(value));
        } catch (e) {
            // Переполнение хранилища или нет доступа не запись
        }
    }

    /**
     * Remove item from storage.
     * @param {string} name
     * @private
     */
    function _removeItem(name) {
        storage && storage.removeItem(LS_PREFIX + name);
    }

    /**
     * Get storage size.
     * @return {number}
     * @private
     */
    function _getLength() {
        return storage && storage.length;
    }

    /**
     * @param {[]} array
     * @param {number} limit
     * @param {*} elementToAdd
     * @private
     */
    function _addToCircularArray(array, limit, elementToAdd) {
        array.push(elementToAdd);
        while (array.length > limit) {
            array.shift();
        }

        return array;
    }

    /**
     * Current volume.
     * @type {number}
     */
    var volume = _getItem('volume');

    /**
     * Last used quality name.
     * @type {string}
     */
    var lastVideoQualityName = _getItem('lastVideoQualityName');

    /**
     * Client id.
     * @type {string}
     */
    var cid = _getItem('cid');

    /**
     * Last used network speed.
     * @type {number}
     */
    var lastSpeed = _getItem('lastSpeed');

    /**
     * Time when last video viewed.
     * @type {string}
     */
    var lastVideoShowTime = _getItem('lastVideoShowTime');

    /**
     * Last shown video in day.
     * @type {number}
     */
    var lastDayVideoShown = _getItem('lastDayVideoShown');

    /**
     * Last shown ad in day.
     * @type {number}
     */
    var lastDayAdvShown = _getItem('lastDayAdvShown');

    /**
     * Stop times for movies.
     * @type {{id: number, time: number}[]}
     */
    var movielastPlayingTime = _getItem('movielastPlayingTime') || [];

    /**
     * Cache for already watched movies.
     * @type {[]}
     */
    var watchedMoviesCache = _getItem('watchedMoviesCache') || [];

    /**
     * Switcher for autoplay.
     * @type {boolean}
     */
    var autoplayEnabled = _getItem('autoplayEnabled');

    /**
     * Switcher for cinema mode.
     * @type {boolean}
     */
    var cinemaModeEnabled = _getItem('cinemaModeEnabled');

    /**
     * Mini player width.
     * @type {number}
     */
    var miniPlayerWidth = _getItem('miniPlayerWidth');

    /**
     * Mini player top position.
     * @type {number}
     */
    var miniPlayerTop = _getItem('miniPlayerTop');

    /**
     * Mini player left position.
     * @type {number}
     */
    var miniPlayerLeft = _getItem('miniPlayerLeft');


    return {
        getVolume: function () {
            return volume;
        },
        setVolume: function (value) {
            _setItem('volume', volume = value);
        },
        getLastVideoQualityName: function () {
            return lastVideoQualityName;
        },
        setLastVideoQualityName: function (value) {
            _setItem('lastVideoQualityName', lastVideoQualityName = value);
        },
        getCid: function () {
            return cid;
        },
        setCid: function (value) {
            _setItem('cid', cid = value);
        },
        getLastSpeed: function () {
            return lastSpeed;
        },
        setLastSpeed: function (value) {
            _setItem('lastSpeed', lastSpeed = value);
        },
        getLastVideoShowTime: function () {
            return lastVideoShowTime;
        },
        setLastVideoShowTime: function (value) {
            _setItem('lastVideoShowTime', lastVideoShowTime = value);
        },
        getLastDayVideoShown: function () {
            return lastDayVideoShown;
        },
        setLastDayVideoShown: function (value) {
            _setItem('lastDayVideoShown', lastDayVideoShown = value);
        },
        getLastDayAdvShown: function () {
            return lastDayAdvShown;
        },
        setLastDayAdvShown: function (value) {
            _setItem('lastDayAdvShown', lastDayAdvShown = value);
        },
        getMovieLastPlayingTime: function (movieId) {
            movielastPlayingTime = Array.isArray(movielastPlayingTime) ? movielastPlayingTime : [];
            movieId = parseInt(movieId, 10);
            for (var i = 0, l = movielastPlayingTime.length; i < l; i++) {
                if (movielastPlayingTime[i].id === movieId) {
                    return movielastPlayingTime[i].time;
                }
            }
            return 0;
        },
        setMovieLastPlayingTime: function (movieId, time) {
            movielastPlayingTime = Array.isArray(movielastPlayingTime) ? movielastPlayingTime : [];
            movieId = parseInt(movieId, 10);
            var timeObject = {id: movieId, time: time};

            for (var i = 0, l = movielastPlayingTime.length; i < l; i++) {
                if (movielastPlayingTime[i].id === movieId) {
                    movielastPlayingTime.splice(i, 1);
                    break;
                }
            }
            _setItem('movielastPlayingTime', _addToCircularArray(movielastPlayingTime, LAST_PLAYING_TIME_CACHE_SIZE, timeObject));
        },
        isWatched: function (movieId) {
            return watchedMoviesCache.indexOf(movieId) != -1;
        },
        addWatched: function (watchedId) {
            _setItem('watchedMoviesCache', _addToCircularArray(watchedMoviesCache, WATCHED_CACHE_SIZE, watchedId));
        },
        getAlreadySeenSimilar: function (count) {
            return watchedMoviesCache.slice(-count);
        },
        isAutoplayEnabled: function () {
            return autoplayEnabled !== false;
        },
        setAutoplayEnabled: function (value) {
            _setItem('autoplayEnabled', autoplayEnabled = value);
        },
        isCinemaModeEnabled: function () {
            return !!cinemaModeEnabled;
        },
        setCinemaModeEnabled: function (value) {
            _setItem('cinemaModeEnabled', cinemaModeEnabled = value);
        },
        getMiniPlayerWidth: function () {
            return miniPlayerWidth;
        },
        setMiniPlayerWidth: function (value) {
            _setItem('miniPlayerWidth', miniPlayerWidth = value);
        },
        getMiniPlayerTop: function () {
            return miniPlayerTop;
        },
        setMiniPlayerTop: function (value) {
            _setItem('miniPlayerTop', miniPlayerTop = value);
        },
        getMiniPlayerLeft: function () {
            return miniPlayerLeft;
        },
        setMiniPlayerLeft: function (value) {
            _setItem('miniPlayerLeft', miniPlayerLeft = value);
        }
    };
})();


/**
 * Called then user clicks on class button in flash player, used on main video page and in feeds
 * @param {string} searchBlock
 * @deprecated use OK.VideoPlayer.likeVideoFromFlash()
 */
window.likeVideoFromFlash = function (searchBlock) {
    if (typeof navigateOnUrlFromJS != 'function') {
        // это анонимная страница, без GWT
        document.location.href = '/';
        return;
    }
    var block = $('#' + searchBlock), sp, url, el, likeParams;
    for (; ;) {
        sp = block.find('.klass_w');
        if (sp.length > 0) {
            break;
        }
        block = block.parent();
        if (block.length == 0) {
            return;
        }
    }

    el = sp.find('a.controls-list_lk')[0];
    if (el != null) { // TODO после запуска эксперимента синх. лайков оставить только "span"
        url = el.href;
    } else {
        el = sp.find('span.controls-list_lk')[0];
        if (el != null) {
            url = el.getAttribute('data-href');
            likeParams = el.getAttribute('data-l-p');
            if (likeParams) {
                url += '&st.v.cl=' + likeParams; // добавим ключ перерисовки (см. /one/app/community/dk/gwt/hook/client/hooks/vote/VoteHook.java:23)
            }
        }
    }

    if (url) {
        navigateOnUrlFromJS(url + '&st.v.ff=on');
    }
}

/**
 * вызывается флешкой - нужно открыть страницу, где можно закачать видео
 * @deprecated Use OK.VideoPlayer.OKVideoOpenUserUpload()
 */
window.OKVideoOpenUserUpload = function () {
    OK.VideoPlayer.OKVideoOpenUserUpload();
}

window.runLinkedVideoCallbackFromJS = function (params) {
    likeCallbackFromJs(params.callbackId + 'Object', params.liked, params.likeCount);
    likeCallbackFromJs(params.callbackId + 'E', params.liked, params.likeCount);
    likeCallbackFromJs('VideoPopup_player_' + params.callbackId + 'Object', params.liked, params.likeCount);
}

/**
 * Called from inline javascript generated by InternalLikeBlock. Called then user clicks on DHTML class button
 * @param {{}} params
 */
window.runLinkedVideoCallback = function (params) {
    var child = document.getElementById('mc' + params.blockId);
    child.parentNode.removeChild(child);
    disableKlassByPlayerId(params.callbackId + 'Object') ||
    disableKlassByPlayerId(params.callbackId + 'E') ||
    disableKlassByPlayerId('VideoPopup_player_' + params.callbackId + 'Object');
}

/**
 * Called from inline javascript generated by InternalLikeBlock. Called then user clicks on DHTML class button
 * @param {{}} params
 */
window.runLinkedVideoCallbackU = function (params) {
    var child = document.getElementById('mc' + params.blockId);
    child.parentNode.removeChild(child);
    runLinkedVideoCallbackFromJS(params);

    // В леере медиатопика нужно классы видео синхронизировать с классами медиатопика.
    require(['jquery'], function ($) {
        var $markers = $('.js-like-marker[data-l-id=' + params.resourceId + ']');
        $markers.each(function () {
            var blockHookId = OK.hookModel.getNearestBlockHookId(this),
                $blockHook = $('#hook_Block_' + blockHookId);
            if ($blockHook.length) {
                likeCallbackFromJs($blockHook.data('playerId') + 'E', params.liked, params.likeCount);
            }
        });
    });
}

/**
 * Disable class button in flash player by player ID. Called then user clicks on DHTML class button
 * @param {string} playerId
 */
window.disableKlassByPlayerId = function (playerId) {
    try {
        document.getElementById(playerId).disableKlassFromJs();
    } catch (e) {
        // Method is not defined. Older version of player.
    }
}

/**
 * Disable/enable class button in flash player by player ID. Called when user clicks on DHTML class button
 * @param {string} playerId
 * @param {boolean} liked
 * @param {number} likeCount
 */
window.likeCallbackFromJs = function (playerId, liked, likeCount) {
    try {
        document.getElementById(playerId).likeCallbackFromJs(liked, likeCount);
    } catch (e) {
        // Method is not defined. Older version of player.
    }
}


/**
 * Ошибка загрузки картинки на видео-витрине
 * @param {HTMLElement} img
 */
OK.VVImageError = function (img) {
    img.parentNode.removeChild(img);
};

/**
 * Плеер дергает эту функцию при закрытие фулл-скрин режима, чтобы обновить все данные на страничке
 * @param {string} movieId
 * @param {string} albumId
 * @param {string} autoplayType
 * @param {number} movieIndex
 */
OK.VideoPlayer.playMovie = function (movieId, albumId, autoplayType, movieIndex) {
    require(['OK/VideoAutoplayLayer'], function (VideoAutoplayLayer) {
        VideoAutoplayLayer.playMovie(movieId, albumId, autoplayType, movieIndex);
    });
};

/**
 * Открыть ролик по movieId
 * @param {string} movieId
 * @param {string=} aid
 * @param {string=} albumId
 * @param {string=} baseUrl
 * @param {string=} playlistIds
 * @param {string=} cmd
 * @param {boolean=} inMini
 * @param {string=} movieBlockId
 */
OK.VideoPlayer.openMovie = function (movieId, aid, albumId, baseUrl, playlistIds, cmd, inMini, movieBlockId) {
    OK.Layers.startLoad();
    require(['OK/VideoAutoplayLayer'], function (VideoAutoplayLayer) {
        var data = {'st.vpl.id': movieId};
        if (aid) {
            data['st._aid'] = aid;
        }
        if (cmd) {
            data['st.cmd'] = cmd;
        }
        if (albumId) {
            data['st.vpl.vs'] = 'album';
            data['st.vpl.albumId'] = albumId;
        }
        if (baseUrl) {
            data['st.vpl.bu'] = baseUrl;
        }
        if (playlistIds) {
            data['st.vpl.vs'] = 'collage';
            data['st.vpl.vp'] = playlistIds;
        }
        if (inMini) {
            data['st.vpl.mini'] = true;
        }
        if (movieBlockId) {
            data['st.vpl.fmbid'] = movieBlockId;
        }
        if (!albumId && !playlistIds) {
            data['st.vpl.dla'] = true;
        }
        VideoAutoplayLayer.navigateToMovie('/dk?cmd=PopLayerVideo', data, movieId, true);
    });
};

/**
 * Открыть ролик по shareId
 * @param {string} shareId
 * @param {string=} aid
 */
OK.VideoPlayer.openShare = function (shareId, aid) {
    require(['OK/VideoAutoplayLayer'], function (VideoAutoplayLayer) {
        var data = {'st.vpl.sid': shareId};
        if (aid) {
            data['st._aid'] = aid;
        }
        VideoAutoplayLayer.navigateToMovie('/dk?cmd=PopLayerVideo', data, shareId, true);
    });
};

/**
 * Плеер дергает эту функцию, чтобы проиграть след. видео по списку
 * @param {string} movieId
 * @param {string} albumId
 * @param {string} autoplayType
 * @param {number} movieIndex
 * @param {function=} callback
 * @param {boolean=} isAuto
 */
OK.VideoPlayer.playNextMovie = function (movieId, albumId, autoplayType, movieIndex, callback, isAuto) {
    require(['OK/VideoAutoplayLayer'], function (VideoAutoplayLayer) {
        VideoAutoplayLayer.playNextMovie(movieId, albumId, autoplayType, movieIndex, callback, isAuto);
    });
};

/**
 * Плеер дергает эту функцию для запроса след. метаданных при автопросмотре в фулл-скрине
 * @param {string} movieId
 * @param {string} albumId
 * @param {string} autoplayType
 * @param {number} movieIndex
 * @param {function=} callback
 */
OK.VideoPlayer.getNextMetadata = function (movieId, albumId, autoplayType, movieIndex, callback) {
    require(['OK/VideoAutoplayLayer'], function (VideoAutoplayLayer) {
        VideoAutoplayLayer.getNextMetadata(movieId, albumId, autoplayType, movieIndex, false, callback);
    });
};

/**
 * Плеер дергает эту функцию для запроса данных о след. ролике при автопросмотре
 * @param {string} movieId
 * @param {string} albumId
 * @param {string} autoplayType
 * @param {number} movieIndex
 * @param {function=} callback
 */
OK.VideoPlayer.getNextMetadataSmall = function (movieId, albumId, autoplayType, movieIndex, callback) {
    require(['OK/VideoAutoplayLayer'], function (VideoAutoplayLayer) {
        VideoAutoplayLayer.getNextMetadata(movieId, albumId, autoplayType, movieIndex, true, callback);
    });
};

/**
 * Плеер дергает эту функцию, чтобы развернуть леер в cinema-mode
 * @param {?string} link
 * @param {?string} id
 */
OK.VideoPlayer.toggleCinemaMode = function (link, id) {
    if (!id && link) {
        // Для старого плеера, в котором не передается id
        id = link.slice(link.lastIndexOf('/') + 1).replace(/(\?|&).*/, '');
    }

    if (!id) {
        require(['OK/VideoAutoplayLayer'], function (VideoAutoplayLayer) {
            OK.VideoPlayer.storage.setCinemaModeEnabled(VideoAutoplayLayer.toggleCinemaMode(true));
        });
    } else {
        var aid = 'Open_Layer_From_Player';
        if (/^[0-9]+$/.test(id)) {
            require(['OK/utils/vanilla'], function (vanilla) {
                var params = vanilla.extractParams(vanilla.unescapeXml(link));
                var movieBlockId = params["st.vpl.fmbid"];
                OK.VideoPlayer.openMovie(id, aid, undefined, undefined, undefined, undefined, undefined, movieBlockId);
            });
        } else {
            OK.VideoPlayer.openShare(id, aid);
        }
    }
    OK.VideoPlayer.closePopLayer();
    OK.logger.success('video.cinema', link ? 'middlesize' : 'toggle');
};

/**
 * Останавливает все плееры.
 */
OK.VideoPlayer.pauseAll = function () {
    if (require.defined('OK/OKVideo')) {
        require('OK/OKVideo').pause();
    }
};

/**
 * Переинициализирует плеер.
 * @param {boolean=} isSmall
 * @param {boolean=} blockWebrtc
 */
OK.VideoPlayer.restart = function (isSmall, blockWebrtc) {
    if (require.defined('OK/OKVideo')) {
        require('OK/OKVideo').retry(0, isSmall, blockWebrtc);
    }
};

/**
 * Останавливает все видеоплееры и схлопывает их обратно в карды.
 */
OK.VideoPlayer.stop = function () {
    if (require.defined('OK/OKVideo')) {
        require('OK/OKVideo').stop();
    }
};

/**
 * Переопределяет и перерисовывает аннотации в ролике
 */
OK.VideoPlayer.changeAnnotations = function (annotations) {
    if (require.defined('OK/OKVideo')) {
        require('OK/OKVideo').changeAnnotations(annotations);
    }
};

/**
 * Плеер дергает этот метод при старте воспроизведения.
 * @param {boolean} isMusic
 * @param {boolean} isLayer
 * @param {boolean} isFeed
 */
OK.VideoPlayer.onPlay = function (isMusic, isLayer, isFeed) {
    require(['OK/music2/app'], function (app) {
        if (!isMusic && !OK.VideoPlayer._isMusicPlaying) {
            // Если музыка играла, сохраним ID трека, чтобы при окончании воспроизведения видео запустить музыку снова
            if (app.playing()) {
                OK.VideoPlayer._isMusicPlaying = true;
                app.pause();
            }
        }
    });
};

/**
 * Плеер дергает этот метод при остановке воспроизведения.
 * @param {boolean} isMusic
 * @param {boolean} isLayer
 * @param {boolean} isFeed
 */
OK.VideoPlayer.onPause = function (isMusic, isLayer, isFeed) {
    // Запустим музыку снова, если она играла при старте воспроизведения видео
    require(['OK/music2/app'], function (app) {
        if (!isMusic && OK.VideoPlayer._isMusicPlaying) {
            OK.VideoPlayer._isMusicPlaying = false;
            app.play();
        }
    })
};

/**
 * Название события для подписки на события видео-плеера.
 * @see https://stash.odkl.ru/projects/ODKL/repos/odkl-videoplayer/browse/html5/js/abstract/Stats.js#119
 */
OK.VideoPlayer.EVENT_NAME = '__videoPlayerEvent';

/**
 * Плеер вызывает этот метод при возникновении события.
 * @param {string} type
 * @param {{}} data
 */
OK.VideoPlayer.onEvent = function (type, data) {
    require(['OK/utils/vanilla'], function (vanilla) {
        vanilla.trigger(document, OK.VideoPlayer.EVENT_NAME, {
            name: type,
            data: data
        });
    });
};

/**
 * Открывает форму фидбэка.
 * @param {string=} link
 */
OK.VideoPlayer.helpFeedback = function (link) {
    require(['OK/utils/utils'], function (utils) {
        utils.ajax({
            url: '/dk',
            data: {
                'cmd': 'PopLayer',
                'st.layer.cmd': 'PopLayerHelpFeedback',
                'st.layer.categorynew': 'SITE_SECTION',
                'st.layer.subcategory': 'VIDEO_AND_MUSIC',
                'st.layer.categoryValue': 'VIDEO',
                'st.layer.origin': 'VIDEO_LAYER',
                'st.layer.badVideoLink': link || window.location.href
            }
        }).done(utils.updateBlockModelCallback);
    });
};

/**
 * Плеер вызывает этот метод для получения списка релейтедов.
 * @param {string} contentId
 * @param {string} provider
 * @param {function=} successCallback
 * @param {function=} errorCallback
 */
OK.VideoPlayer.getRelated = function (contentId, provider, successCallback, errorCallback) {
    require(['OK/utils/utils'], function (utils) {
        utils.ajax({
            url: '/dk',
            data: {
                'cmd': 'videoRelatedCommand',
                'id': contentId,
                'count': 36
            }
        }).done(successCallback).fail(errorCallback);
    });
};

/**
 * Отправляет статистику эмбеда в Яндекс
 * @link https://yandex.ru/support/video/partners/jsapi.xml
 * @param {string} event Название события
 * @param {number=} param1
 * @param {number=} param2
 */
OK.VideoPlayer.yandexStat = function (event, param1, param2) {
    var data = {event: event};

    if (typeof param1 !== 'undefined') {
        switch (event) {
            case 'volumechange':
                data.volume = param1;
                break;
            default:
                data.time = param1;
        }
    }

    if (typeof param2 !== 'undefined') {
        switch (event) {
            case 'error':
                data.code = param2;
                break;
            case 'rewound':
                data.previousTime = param2;
                break;
            case 'adShown':
            case 'started':
            case 'timeupdate':
                data.duration = param2;
                break;
            case 'volumechange':
                data.muted = param2;
                break;
        }
    }
    window.parent.postMessage(data, '*');
};

/**
 * Плеер вызывает этот метод для добавление и удаление в альбом "посмотреть позже".
 * @param {number} movieId
 * @param {boolean} status
 * @param {function=} successCallback
 * @param {function=} errorCallback
 */
OK.VideoPlayer.watchLater = function (movieId, status, successCallback, errorCallback) {
    require(['OK/utils/utils'], function (utils) {
        utils.ajax({
            url: '/dk',
            data: {
                'cmd': 'VideoWatchLaterBlock',
                'st.vvwl.action': status ? 'watchLater' : 'removeWatchLater',
                'st.vvwl.movieId': movieId
            }
        }).done(successCallback).fail(errorCallback);
    });
};

/**
 * Вызывается при ошибке видео (показе "печеньки")
 * @param {string} message Ключ сообщения об ошибке
 * @param {number=} time Позиция прогресс-бара в секундах
 */
OK.VideoPlayer.yandexError = function (message, time) {
    var code = 0;

    switch (message && message.toLowerCase().replace(/_/g, '')) {
        // Недоступное видео
        case 'movienotready':
        case 'uploading':
        case 'creating':
        case 'processing':
        case 'offline':
        case 'livenotstarted':
        case 'liveended':
        case 'onmoderation':
            code = 100; // Прочие случаи недоступного видео.
            break;
        case 'moviedeleted':
        case 'groupnotfound':
        case 'usernotfound':
            code = 101; // Видео удалено.
            break;
        case 'blocked':
        case 'censored':
            code = 102; // Видеоролик или учетная запись заблокирована.
            break;
        case 'notfound':
            code = 103; // Видеоролик не существует либо URL не поддерживается.
            break;

        // Ограничение доступа к видеоролику
        case 'copyrightsrestricted':
            code = 150; // Прочие ограничения просмотра видео.
            break;
        case 'accessdenied':
        case 'groupaccessdenied':
        case 'closedprofile':
        case 'limitedaccess':
        case 'movienoaccess':
        case 'weberrornoaccess':
            code = 151; // Недостаточно прав для просмотра видео.
            break;
        case 'videoembeddisabled':
        case 'videoembeddisabledpartner':
            code = 152; // Видео запрещено к проигрыванию на других сайтах.
            break;
        case 'videoembeddisabledcountry':
        case 'movieunavailableforregion':
            code = 153; // Видео запрещено к проигрыванию в данном регионе.
            break;

        // Прочее
        case 'player':
            code = 5; // Сбой работы плеера (ошибки воспроизведения HTML-проигрывателя и др.).
            break;
        default:
            code = 0; // Прочие ошибки.
            break;
    }

    OK.VideoPlayer.yandexStat('error', time || 0, code);
};

OK.VideoPlayer.closePopLayer = function () {
    OK.hookModel.setHookContent('PopLayer', '');
};

/**
 * Трекаем события из плеера через MyTracker
 * @param {string} name
 * @param {?{}} params
 */
OK.VideoPlayer.pushTmrStat = function (name, params) {
    require(['OK/metrics/MyTrackerService'], function (tmr) {
        tmr.myTrackerService.trackCustomGoal(name, {
            params
        });
    });
};
