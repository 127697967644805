(function () {
    /**
     * Обрабатывает строку с использование string substitution: format("Test: %s - value", "var")
     * @returns отформатированная строка (напр., "Test: var - value")
     */
    function format() {
        var string = arguments[0];

        for (var i = 1; i < arguments.length; i++) {
            string = string.replace(/%s/, arguments[i]);
        }

        return string;
    }

    function formatError(error) {
        var stack = error.stack;

        if (stack) {
            var msg = error.message;

            var messageStartIndex = stack.indexOf(msg);
            if (messageStartIndex !== -1) {
                var i = messageStartIndex + msg.length;
                msg = stack.substring(0, i);
                stack = stack.substring(i + 1);
            }

            var formattedStack = stack
                .split('\n')
                .filter((item, index) => index !== 0 && !item.includes('chrome-extension'))
                .join('\n');

            error.stack = `${msg}\n${formattedStack}`;
        }

        return error;
    }

    var originalConsoleObject = {
        log: window.console.log,
        warn: window.console.warn,
        error: window.console.error
    };

    Object.assign(window.console, {
        info: function () {
            OK.Tracer.log(`[Info]: ${Array.from(arguments).join(' ')}`);
            originalConsoleObject.log.apply(this, arguments);
        },
        warn: function () {
            OK.Tracer.log(`[Warning]: ${Array.from(arguments).join(' ')}`);
            originalConsoleObject.warn.apply(this, arguments);
        },
        error: function () {
            if (arguments.length > 0) {
                var item = arguments[0];
                var error;
                if (item instanceof Error) {
                    error = item;
                } else if (typeof item === 'string') {
                    var args = Array.from(arguments);
                    error = formatError(
                        new Error(item.includes('%s')
                            ? format.apply(this, args)
                            : args.join(' ')
                        )
                    );
                } else {
                    error = formatError(new Error(JSON.stringify(item)));
                }
                OK.Tracer.error(error);
            }
            originalConsoleObject.error.apply(this, arguments);
        },
        originalError: function() {
            originalConsoleObject.error.apply(this, arguments);
        }
    });
}());
