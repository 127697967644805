var OK = window.OK || (window.OK = {});

OK.clickLog = OK.clickLog || {
    getCounter: OK.fn.empty,
    incCounter: OK.fn.empty,
    decCounter: OK.fn.empty
};

/**
 * Хранит текущий контекст логирования ошибок.
 *
 * Основной юзкейс
 * Пусть у нас есть функция которая выполняется в таймере: <code>fn = function () {...}; window.setTimeout(fn, 100);</code>
 * Тогда любые исключения произошедшие внутри этой функции не будут логироваться. Чтобы включить логирование достаточно
 * обернуть целевую функцию <code>fn</code> с помощью <code>OK.fn.logging</code>: <code>window.setTimeout(OK.fn.logging(fn), 100)</code>.
 * Теперь все ошибки будут
 * логироваться с помощью логгера установленного методом <code>setUp</code> (обычно вызывается в процесс работы JavascriptHook)
 *
 * @deprecated
 *      Вместо OK.logging надо использовать:
 *      - в `res/js/app` модуль `OK/StatLogger` или `OK/logger` (последний, если не нужна предобработка логируемых данных);
 *      - в `apps/projects` модуль `ok/statistics/StatLogger`.
 *
 */
OK.logging = (function () {
    return {
        /*
        * Является ли текущий агент - селениум ботом (проверяется наличием куки "_okSat" в GWTUtils)
        */
        isSeleniumBot: false,
        /**
         * Дефолтный текущий логгер
         */
        currentLoggerFn: OK.fn.empty,

        /**
         * Установить дефолтный текущий логгер ошибок
         * @param p_loggerFn
         */
        setUp: function (p_loggerFn) {
            OK.logging.currentLoggerFn = p_loggerFn;
        },
        /**
         * Сбросить дефолтный текущий логгер
         */
        tearDown: function () {
            OK.logging.currentLoggerFn = OK.fn.empty;
        },
        alert: function (s) {
            if (OK.fn.isDebug()) {
                if (OK.fn.isAlertsEnabled()) {
                    alert(s);
                } else {
                    console.error(s);
                }
            }
        },
        info: function () {
            OK.Tracer.log('[Log]: ' + Array.from(arguments).join(' '));
            if (OK.fn.isDebug()) {
                try {
                    console && console.log.apply(console, arguments);
                } catch (ex) {
                }
            }
        },
        logger: {  // Implemented in one.widget.test.client.widgets.uitest.Logger

            queue: [],
            replayQueue: function () {
                var i, q = this.queue;

                // cache q.length to prevent infinite looping
                // if any logging method hasn't been overridden
                var qLen = q.length;

                for (i = 0; i < qLen; i++) {
                    q[i].call();
                }
                this.queue = [];
            },

            // API stub defined in one.widget.test.client.widgets.uitest.Logger

            /** API stub that is supposed to be overridden */
            error: function (whereHappened, message) {
                this.queue.push(OK.fn.deferred(this, 'error', arguments));
            },

            /** API stub that is supposed to be overridden */
            success: function (operation, param1, param2, rawData) {
                this.queue.push(OK.fn.deferred(this, 'success', arguments));
            },

            force: function (operation, param1, param2) {
                this.queue.push(OK.fn.deferred(this, 'force', arguments));
            },

            /** API stub that is supposed to be overridden */
            duration: function (operation, duration, param1, param2) {
                this.queue.push(OK.fn.deferred(this, 'duration', arguments));
            },

            /** API stub that is supposed to be overridden */
            clob: function (operation, clob, param1, param2) {
                this.queue.push(OK.fn.deferred(this, 'clob', arguments));
            },

            /** API stub that is supposed to be overridden */
            trace: function (operation, info, applicationPartWhereExcCaught, errorName) {
                this.queue.push(OK.fn.deferred(this, 'trace', arguments));
            },

            /** API stub that is supposed to be overridden */
            raw: function (operation, data) {
                this.queue.push(OK.fn.deferred(this, 'raw', arguments));
            },

            errorEx: function (exception, applicationPartWhereExcCaught, param2, customOperation) {
                var operation = 'error';
                if (customOperation) {
                    operation = customOperation;
                }
                var clob = "Fatal JavaScript Error, please see log:\n" +
                    "exception ='" + exception + "'\n" +
                    "stacktrace=" + exception.stack;
                this.clob(operation, clob, applicationPartWhereExcCaught, param2);
            },

            /**
             * Метод, позволяющий отправить ошибку в Graylog.
             *
             * Фильтр:
             * @see https://graylog.service.local.odkl.ru/search?q=facility%3A+odnoklassniki-web+AND+%22client.error%22
             */
            saveAsClientError: function (exception) {
                // При unhandledrejection могут прилетать пустые значения. Нужно их выкинуть
                if (!exception) {
                    return;
                }

                function func(cfg) {
                    if (!cfg.isLogClientErrorsToGraylogEnabled) {
                        return;
                    }
                    var operation = 'undefined-error';
                    var data = {
                        stateId: OK.getCurrentDesktopModelId(),
                    }
                    var stack = exception.stack;
                    if (stack) {
                        var stackLowerCase = stack.toLowerCase();

                        if (stackLowerCase.includes('res/react/')) {
                            operation = 'react-error';
                        } else if (stackLowerCase.includes('res/js/') || stackLowerCase.includes('res/assets/')) {
                            operation = 'modules-error';
                        } else if (stackLowerCase.includes('web/gwt/')) {
                            operation = 'gwt-error';
                        }
                    }

                    this.errorEx(exception, 'client.error', data.stateId, operation);
                }

                var funcWithContext = func.bind(this);

                var moduleId = 'OK/pms!debugConfiguration';
                if (require.defined(moduleId)) {
                    /* Если модуль определён, получим его из контекста Require и сразу выполним функцию активации */
                    funcWithContext(require(moduleId));
                } else {
                    /* Если модуль НЕ определён, выполним процедуру загрузки и проведём отложенную активацию */
                    require([moduleId], funcWithContext);
                }
            },


            /**
             * Округляем среднее арифметическое от duration:
             *  — 0 .. 1000 округляем до сотен
             *  — 1000 .. 10000 округляем до тысяч
             *  — 10 000 ... 60 000 округляем до десятков тысяч
             * значение больше 60 000 мс считаем аномально долгим
             *
             * 49 → 0
             * 50 → 100
             * 1499 → 1000
             * 1500 → 2000
             * 14999 → 10000
             * 15000 → 20000
             *
             * @param duration в миллисекундах
             */
            durationScalar: function (operation, param, duration) {
                if (duration != null && duration < 60000) {
                    var place = 10000; // округляем до тысячей: 14999 → 10000, 15000 → 20000
                    if (1000 <= duration && duration < 10000) {
                        place = 1000; // округляем до тысячей: 1499 → 1000, 1500 → 2000
                    } else if (duration < 1000) {
                        place = 100; // округляем до сотен: 49 → 0, 50 → 100
                    }
                    duration = Math.round(duration / place) * place;
                } else {
                    duration = 60000;
                }

                this.success(operation, param, "" + duration); // ""+duration конвертируем в стринг, чтобы в GWT-классе Logger не падала ошибка
            }
        },
        repository: (function () {
            var agg = {}, plain = [];

            function isArray(a) {
                return a && Object.prototype.toString.call(a) === '[object Array]';
            }

            function isObject(a) {
                return a && Object.prototype.toString.call(a) === '[object Object]';
            }

            function isDefined(a) {
                return a !== undefined;
            }

            function toZeroFilledArray(n) {
                var t = [];
                while (n) t[--n] = 0;
                return t;
            }

            function prepareData(obj) {
                var str = JSON.stringify(obj);
                if (!str || str.length <= 2) {
                    return null;
                }
                return encodeURIComponent(str);
            }

            function getAggData() {
                var r = prepareData(agg);
                agg = {};
                return r;
            }

            function getPlainData() {
                var r = prepareData(plain);
                plain = [];
                return r;
            }

            function savePlain(operation, param1, param2, duration, info, clob, rawData) {
                var o = {'o': operation};
                param1 && (o['p1'] = param1);
                param2 && (o['p2'] = param2);
                duration && (o['d'] = duration);
                info && (o['i'] = info);
                clob && (o['c'] = clob);
                rawData && (o['r'] = rawData);
                plain.push(o);
            }

            function saveAggregated(arr) {
                saveAggregated0(agg, arr);
            }

            function saveAggregated0(root, arr) {
                var lvl = arr.pop(), result = root;
                if (typeof lvl === 'string') {
                    result = !result ? {} : !isObject(result) ? {'n/a': result} : result;
                    result[lvl] = saveAggregated0(result[lvl], arr);
                } else if (isObject(result)) {
                    result['n/a'] = saveAggregated0(result['n/a'], [lvl]);
                } else if (isArray(result)) {
                    result.push(lvl ? lvl : 0);
                } else if (lvl) {
                    result = toZeroFilledArray(result);
                    result.push(lvl); // duration
                } else {
                    result = 1 + (result || 0);
                }
                return result;
            }

            return {
                addLog: function (operation, duration, info, clob, rawData, param1, param2) {
                    if (info || clob || rawData) {
                        savePlain(operation, param1, param2, duration, info, clob, rawData);
                    } else {
                        var arr = [];
                        isDefined(duration) && arr.push(duration);
                        isDefined(param2) && arr.push("" + param2);
                        isDefined(param1) && arr.push("" + param1);
                        arr.push("" + operation);
                        saveAggregated(arr);
                    }
                },
                getRequestData: function () {
                    var params = [];
                    var aggData = getAggData();
                    var plainData = getPlainData();

                    if (aggData) {
                        params.push('a=' + aggData);
                    }

                    if (plainData) {
                        params.push('p=' + plainData);
                    }

                    if (params.length > 0) {
                        // Доклеиваем к каждому запросу `StatId`, чтобы иметь возможность его извлечь на сервере.
                        // @see https://jira.odkl.ru/browse/ECOM-1791
                        params.push(OK.STAT_ID_PARAMETER + '=' + OK.getStatId());

                        return params.join('&');
                    }

                    return null;
                }
            };
        }())
    };
})();
/**
 * @deprecated
 *      Вместо OK.logger и OK.logging.logger надо использовать:
 *      - в `res/js/app` модуль `OK/StatLogger` или `OK/logger` (последний, если не нужна предобработка логируемых данных);
 *      - в `apps/projects` модуль `ok/statistics/StatLogger`.
 */
OK.logger = OK.logging.logger;
/**
 * @deprecated
 *      Вместо OK.logger.repository и OK.logging.repository надо использовать:
 *      - в `res/js/app` модуль `OK/StatLogger` или `OK/logger` (последний, если не нужна предобработка логируемых данных);
 *      - в `apps/projects` модуль `ok/statistics/StatLogger`.
 */
OK.logger.repository = OK.logging.repository;
define('OK/logger', OK.logger);
