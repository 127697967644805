var OK = window.OK || (window.OK = {});

/**
 * Механизм подписок на кастомное событие, а также вызов подписчиков.
 * il - event listener (?)
 */
OK.il = {
    /**
     * Мапа обработчиков.
     * Хранится в виде: producer id -> массив обработчиков { id, handle, handlerId }[];
     */
    handlers: {},
    /**
     * Добавление нового подписчика.
     * ah - addHandler.
     * @param {{
     *      id: string | number,
     *      handle: (event: object, arg: any) => void,
     *      handlerId?: string | number
     * }} handler - сущность обработчик.
     */
    ah: function (handler) {
        if (handler && handler.handle && handler.id) {
            var id = handler.id;
            if (this.handlers[id] == null) {
                this.handlers[id] = [];
            }
            this.handlers[id][this.handlers[id].length] = handler;
        }
    },
    /**
     * Вызов всех обработчиков подписанных на producer id.
     * f – fire.
     * @param {object} event - объект ивента. Будет передан первым аргументом в обработчик.
     * @param {string | number} id - id на который подписаны обработчики.
     * @param {any} arg - аргумент. Будет передан вторым параметром в обработчик.
     */
    f: function (event, id, arg) {
        if (id && this.handlers[id]) {
            var length = this.handlers[id].length, handlers = this.handlers[id];
            for (var i = 0; i < length; i++) {
                var handler = handlers[i];
                handler.handle(event, arg);
            }
        }
    },
    /**
     * Отписка обработчика у producer id по handlerId.
     * rh – removeHandler.
     * @param {{
     *      id: string | number,
     *      handlerId: string | number,
     * }} handler - данные об обработчике, которые необходимо отписать.
     */
    rh: function (handler) {
        if (handler && handler.id) {
            var id = handler.id;
            if (this.handlers[id] == null || handler.handlerId == null) {
                return;
            }
            var length = this.handlers[id].length, handlers = this.handlers[id], handlerId = handler.handlerId,
                foundIndex = -1;
            for (var i = 0; i < length; i++) {
                var handler = handlers[i];
                if (handler.handlerId == handlerId) {
                    foundIndex = i;
                    break;
                }
            }
            if (foundIndex > -1) {
                this.handlers[id].splice(foundIndex, 1);
            }
        }
    }
};

/**
 * Stop event propagation. Used to prevent click processing on element
 *
 * @param event
 * @return {Boolean}
 */
OK.stop = function (event) {
    event = event || window.event;
    if (event.type === 'click' && OK.clickLog.getCounter() > 0) {
        event['log-clicks'] = true;
        return false;
    }
    if (event.stopPropagation) {
        event.stopPropagation();
    } else {
        event.cancelBubble = true;
    }
    return false;
};

OK.hardStopEvent = function (event) {
    event = event || window.event;
    if (event.stopPropagation) {
        event.stopPropagation();
    } else {
        event.cancelBubble = true;
    }
    return false;
};


// был ли эвент обработан, но пропущен на верх из-за лог кликс.
OK.isStopped = function (event) {
    event = event || window.event;
    return event && event['log-clicks'];
};

/**
 * Способ получить target для события работающий во всех браузерах.
 */
OK.eventTarget = function (event) {
    var event = event || window.event;
    var target;
    if (event.target) {
        target = event.target;
    } else if (event.srcElement) {
        target = event.srcElement;
    } else {
        target = window.document;
    }
    if (target.nodeType === 3) {
        target = target.parentNode;
    }
    return target;
};

/**
 * Generate submit event for form element
 */
OK.submit = function (element) {
    var formElement = element;
    if (element.tagName.toLowerCase() !== 'form') {
        formElement = element.parentNode;
    }
    if (document.createEvent) {
        var evt = document.createEvent('Event');
        evt.initEvent('submit', true, true);
        formElement.dispatchEvent(evt);
    } else if (document.createEventObject) {
        var evt = document.createEventObject();
        formElement.fireEvent('onsubmit', evt);
    }
};

OK.onload = (function (_wnd) {
    var onloadCallbacks = [];
    var callAsync = true;

    function addCallback(onloadCallback, requiredScripts) {
        if (requiredScripts != null && requiredScripts.length > 0) {
            var originalCallback = onloadCallback;
            onloadCallback = OK.fn.delegate(
                OK.loader.use,
                [originalCallback, requiredScripts]
            );
        }

        if (callAsync) {
            onloadCallbacks.push(onloadCallback);
        } else {
            try {
                onloadCallback.call(_wnd);
            } catch (e) {
                OK.logging.alert(`Onload callback error after immediate execute: ${e.message}`);
            }
        }
    }

    function fire() {
        callAsync = false;
        for (var i = 0; i < onloadCallbacks.length; i++) {
            var callback = onloadCallbacks[i];
            try {
                callback.call(_wnd);
            } catch (e) {
                OK.logging.alert(`Onload callback error after startup execute: ${e.message}`);
            }
        }
        onloadCallbacks.length = 0;
    }

    return {
        addCallback,
        fire
    };
})(window);

OK.afterWindowOnloadAttach = function (fn) {
    var deffered = function () {
        if (performance.timing.loadEventEnd > 0) {
            fn.call();
        } else {
            setTimeout(deffered, 100);
        }
    };

    if (performance.timing.loadEventStart > 0) {
        setTimeout(deffered, 10);
    } else {
        window.addEventListener("load", function () {
            setTimeout(deffered);
        });
    }
};
