var OK = window.OK || (window.OK = {});

OK.bottomPanelHeight = function () {
    var cl = document.documentElement.classList;

    if (cl.contains("__with-auth-banner")) {
        return 72;
    }

    return 0;
};
