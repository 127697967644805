var OK = window.OK || (window.OK = {});

/**
 * Получаем ширину вскролла
 */
OK.scrollBar = function () {
    // #sc, #sch should be already on page
    OK.scrollBar.width = document.getElementById('sc').offsetWidth - document.getElementById('sch').offsetWidth;
};

/**
 * Выстовляем ширину для контента после того, как на странице загрузился скролл
 */
OK.setContentWidth = function () {
    var scrollWidth = OK.scrollBar.width;
    var windowWidth = window.innerWidth - scrollWidth;

    // Setting min-width equal 1000 cause result window width must be (1000 + scrollWidth) < 1024
    windowWidth = Math.max(1000, windowWidth);
    var topPanel = document.getElementById('topPanel');
    var bodySwitcher = document.getElementById('hook_Block_BodySwitcher');
    var mainContainer = document.getElementById('mainContainer');

    // there are pages where can be not topPanel and bodySwitcher
    if (bodySwitcher) {
        bodySwitcher.style.width = windowWidth + 'px';
    }
    if (topPanel) {
        topPanel.style.width = windowWidth + 'px';
    }

    var isLayoutOptimizationOn = Boolean(document.getElementsByClassName("layout-optimization")[0]);

    if (mainContainer && isLayoutOptimizationOn) {
        mainContainer.style.width = windowWidth + 'px';
    }
};
