var OK = window.OK || (window.OK = {});

var flashVerForGWT = [0, 0, 0];
var okFlashVersion = [0, 0, 0];
var minimumFlashVersion = 9;

window.displayFlashContainer = function () {
    var d = document;
    var flashDiv = d.getElementById("flashMainContainerDiv");
    if (flashDiv == null) {
        return;
    }
    var fDS = flashDiv.style;
    var oFDS = d.getElementById("flashOldFlashContainerDiv").style;
    var nFDS = d.getElementById("flashNoFlashContainerDiv").style;
    hide(fDS);
    hide(oFDS);
    hide(nFDS);
    try {
        var html5Used = flashDiv.className.indexOf("apiNoFlashWarning") != -1 && typeof window.postMessage != 'undefined';
        if (!html5Used && okFlashVersion[0] < minimumFlashVersion) {
            if (okFlashVersion[0] == 0) {
                show(nFDS);
            } else {
                show(oFDS);
            }
        } else {
            show(fDS);
            if (flashDiv.className.indexOf("apiNoHide") == -1) {
                //do not try to show flash when there is popLayer
                //this tricky case happends when whole page is fully loaded (aka refresh) on application/game page after paying with virtual money.
                var popLayer = document.getElementById("hook_PopLayer_popLayer");
                if (popLayer != null) {
                    // for fDS visibility should be used - same as in FlashUtilities
                    fDS.visibility = "hidden";
                }
            }
        }
    } catch (e) {
        show(nFDS);
    }

    function show(s) {
        s.display = "block";
    }

    function hide(s) {
        s.display = "none";
    }

};

/*
 * Регистрируем версию флеш-плеера пользователя
 */

(function (win, nav) {
    try {
        var _NULL = null,
            UNDEF = "undefined",
            OBJECT = "object",
            SHOCKWAVE_FLASH = "Shockwave Flash",
            SHOCKWAVE_FLASH_AX = "ShockwaveFlash.ShockwaveFlash",
            FLASH_MIME_TYPE = "application/x-shockwave-flash",
            playerVersion = [0, 0, 0],
            d = _NULL,
            navPlugins = nav.plugins,
            _parseInt = parseInt,
            regexGroup = "$1";

        function getFlashActiveXObjectVersion() {
            try {
                var prefix = SHOCKWAVE_FLASH_AX + ".";
                for (var i = 11; i > 8; i--) {
                    try {
                        var flash = new ActiveXObject(prefix + i);
                        var version = flash.GetVariable("$version");
                        return version;
                    } catch (e) {
                    }
                }
                return _NULL;
            } catch (e) {
                return _NULL;
            }
        }

        if (typeof navPlugins != UNDEF && typeof navPlugins[SHOCKWAVE_FLASH] == OBJECT) {
            d = navPlugins[SHOCKWAVE_FLASH].description;
            var mimeTypes = nav.mimeTypes;
            if (d && !(typeof mimeTypes != UNDEF && mimeTypes[FLASH_MIME_TYPE] && !mimeTypes[FLASH_MIME_TYPE].enabledPlugin)) { // navigator.mimeTypes["application/x-shockwave-flash"].enabledPlugin indicates whether plug-ins are enabled or disabled in Safari 3+
                d = d.replace(/^.*\s+(\S+\s+\S+$)/, regexGroup);
                playerVersion[0] = _parseInt(d.replace(/^(.*)\..*$/, regexGroup), 10);
                playerVersion[1] = _parseInt(d.replace(/^.*\.(.*)\s.*$/, regexGroup), 10);
                playerVersion[2] = /[a-zA-Z]/.test(d) ? _parseInt(d.replace(/^.*[a-zA-Z]+(.*)$/, regexGroup), 10) : 0;
            }
        } else if (typeof win.ActiveXObject != UNDEF) {
            try {
                var d = getFlashActiveXObjectVersion();
                if (d) {
                    d = d.split(" ")[1].split(",");
                    playerVersion = [_parseInt(d[0], 10), _parseInt(d[1], 10), _parseInt(d[2], 10)];
                }
            } catch (e) {
            }
        }
        win.okFlashVersion = playerVersion;
        win.flashVerForGWT = playerVersion;
        OK.onload.addCallback(function () {
            require(['OK/cookie'], function (cookie) {
                cookie.setCookie("_flashVersion", playerVersion[0], 30, "/");
                if (playerVersion[0] > 0) {
                    cookie.setCookie("_flash7dVersion", playerVersion[0], 7, "/");
                }
            });
        });
    } catch (e) {
    }
})(window, navigator);
