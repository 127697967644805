var OK = window.OK || (window.OK = {});

OK.sendBeacon = function (url, data) {
    var sendUsingImage = true;
    if (navigator.sendBeacon) {
        var headers = {
            type: 'application/x-www-form-urlencoded'
        };
        var blob = new Blob([data], headers);
        if (navigator.sendBeacon(url, blob)) {
            sendUsingImage = false;
        }
    }

    if (sendUsingImage) {
        var blob = new Blob([data]);
        var xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded")
        var statId = OK.getStatId();
        if (statId) {
            xhr.setRequestHeader(OK.STAT_ID_HEADER, statId);
        }
        xhr.send(blob);
    }
};
