var OK = window.OK || (window.OK = {});

OK.cnst = {
    pageCtx: window.pageCtx,
    staticUrl: window.pageCtx['staticResourceUrl']
};

OK.navigation = {
    SPLITER: "<!--&-->",
    HEADER: "Rendered-Blocks",
    redirect: false //API stub that is supposed to be overridden
};

OK.getCurrentStateLink = OK.getCurrentStateLink || function () {
    // NavigationFactory ещё не загрузился
    return '/dk?' + window.pageCtx.state.replace(/&amp;/g, '&');
};

OK.pf = {};
